.home-wrapper{
    width: 100%;
    height: auto;
}

.home-container{
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
    height: auto;
    padding: 10px;
    /* background-color: red; */
}

.category-widget-wrapper{
    width: 100%;
    max-width: var(--MAX_WIDTH);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.category-widget-wrapper > .scroller{
    position: absolute;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    cursor: pointer;
}
.category-widget-wrapper > .scroller.left{
    left: -15px;
}
.category-widget-wrapper > .scroller.right{
    right: -15px;
}
.category-widget{
  width: 100%;
  height: 100px;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
}
.category-widget > .widget{
  width: auto;
  min-width: 80px;
  height: 100%;
  margin-bottom: 10px;
  padding: 0px 5px;
  margin: 0px 10px 10px 10px;
  /* background-color: red; */
  cursor: pointer;
}
.category-widget > .widget.active > span{
    width: auto;
    text-align: center;
    background-color: var(--MAIN_COLOR);
    border-radius: 5px;
    color: white;
    padding: 0px 10px;
}
.category-widget > .widget > img{
  height: 70px;
  width: 70px;
  object-fit: cover;
}
.category-widget > .widget span{
  cursor: pointer;
  white-space: nowrap;
}
.category-widget > .widget > img{
  cursor: pointer;
}
.category-widget > .widget > img *{
  cursor: pointer;
}


.category-widget::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}



.filter-widget{
    width: 100%;
    height: auto;
    margin-top: 10px;
    /* background-color: green; */
    position: relative;
    overflow-x: auto;
    /* overflow-y: hidden; */
}
.filter-widget > .widget{
    background-color: var(--MAIN_COLOR);
    width: calc(50% - 35px);
    border-radius: 15px;
    color: white;
    cursor: pointer;
    padding: 5px 15px;
}
.filter-widget > .widget span{
    cursor: pointer;
}
.filter-widget > .widget > svg{
    margin: 0px 5px;
    cursor: pointer;
    font-size: 1.2em;
    margin-right: 10px;
}
.filter-widget > .widget > span{
    width: 100%;
}

.react-select{
    width: 100%;
    z-index: 10;
}


.home-popup{
  height: auto;
  width: calc(100%);
  /* min-width: 350px; */
  padding: 20px;
  background-color: white;
  /* position: fixed; */
  /* top: 37px; */
  /* left: 0px; */
  border-radius: 10px;
  /* box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2); */
  color: black;
  z-index: 9;
  margin-top: 10px;
}
.home-popup.hidden{
  display: none;
}
.home-popup > .title{
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 5px;
  letter-spacing: 1.2px;
}
.home-popup > .subtitle{
  color: grey;
  margin-bottom: 20px;
}
.home-popup > hr{
  width: calc(100% - 0px);
}
.home-popup > .action{
  width: 100%;
  cursor: pointer;
}
.home-popup > .action > .button{
  margin-left: 20px;
}

.home-popup > .rating{
    width: 350px;
    align-self: center;
}
.home-popup > .rating > .ratingwidget{
  margin: 0px 15px;
  cursor: pointer;
}
.home-popup > .rating > .ratingwidget > svg{
  margin-bottom: 5px;
  color: grey;
}
.home-popup > .rating > .ratingwidget > svg.active{
  color: var(--BTN_COLOR);
}


.home-popup > .delivery{
    width: 100%;
    /* background-color: red; */
}
.home-popup > .delivery > .react-select{
    width: 100%;
    color: black;
    text-align: start;
    cursor: pointer;
    min-width: 350px;
}








.promotions-widget::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
.promotions-widget::-webkit-scrollbar-track {
  background: white; 
  border-radius: 5px;
}
.promotions-widget::-webkit-scrollbar-thumb {
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
.promotions-widget::-webkit-scrollbar-thumb:hover {
  background: var(--BTN_COLOR); 
}

.promotions-widget{
  width: 100%;
  height: 160px;
  max-width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.promotions-widget > .widget{
  width: calc(50% - 10px);
  min-width: calc(50% - 10px);
  height: calc(100% - 10px);
  /* background-color: red; */
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.promotions-widget > .widget > .fgcolor{
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(110deg, var(--MAIN_COLOR) 65%, rgba(0, 0, 0, 0) 65%);
  z-index: 2;
  cursor: pointer;
}
.promotions-widget > .widget > .fgcolor > .title{
  max-width: 65%;
  /* font-family: avant-garde-md; */
  padding: 10px;
  font-weight: 500;
  /* letter-spacing: 1.1px; */
  color: white;
  cursor: pointer;
}
.promotions-widget > .widget > .fgcolor > .subtitle{
  max-width: 65%;
  padding: 0px 10px;
  /* font-weight: bold; */
  color: white;
  cursor: pointer;
}
.promotions-widget > .widget > img{
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
  cursor: pointer;
  background-color: var(--BG_WHITE_COLOR);
}



.restaurants-widget{
  width: 100%;
  height: auto;
}
.restaurants-widget > .header{
  width: 100%;
  height: auto;
}
.restaurants-widget > .header > .title{
  font-size: 1.2em;
  /* font-weight: bold; */
  /* font-family: avant-garde-md; */
}
.restaurants-widget > .header > .actions > svg{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 5px;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.restaurants-widget > .header > .actions > svg.disabled{
  color: rgb(179, 179, 179);
  background-color: rgb(232, 232, 232);
}
.restaurants-widget > .header > .actions > svg.enabled{
  color: black;
  background-color: rgb(211, 211, 211);
}
.restaurants-widget > .header > .actions > span{
  margin-right: 20px;
  cursor: pointer;
  /* font-weight: bold; */
  font-size: 0.8em;
}
.restaurants-widget > .content{
  width: 100%;
  height: auto;
  /* min-height: 160px; */
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  /* background-color: red; */
  margin-top: 10px;
}
/* width */
.restaurants-widget > .content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}


.restaurant-card{
  width: calc( (100% / 3) - 10px );
  min-width: calc( (100% / 3) - 10px );
  height: auto;
  /* margin-right: 10px;
  margin-bottom: 10px; */
  margin: 0px 10px 10px 10px;
  /* background-color: blue; */
  text-decoration: none;
  color: black;
}
.restaurant-card > img{
  height: 160px;
  width: calc(100%);
  object-fit: cover;
  border-radius: 10px;
  background-color: var(--BG_WHITE_COLOR);
}
.restaurant-card > .title{
  font-size: 0.9em;
  font-weight: 500;
  /* font-family: avant-garde-md; */
  width: 100%;
  text-align: start;
  margin: 5px 0px;
}
.restaurant-card > .subtitle{
  width: 100%;
  color: grey;
}























@media (max-width: 767px) { /* mobile */

    .promotions-widget > .widget{
        width: calc(1000% - 10px);
        min-width: calc(100% - 10px);
    }

    .restaurant-card{
        width: calc( 100% - 20px );
        min-width: calc( 100% - 20px );
        height: auto;
        /* margin-right: 10px;
        margin-bottom: 10px; */
        margin: 10px;
        padding-bottom: 10px;
        /* background-color: blue; */
        border-bottom: thin solid rgb(200, 200, 200, 0.2);
    }

    .home-popup > .rating{
        width: 90%;
    }
    .home-popup > .delivery > .react-select{
        min-width: 90%;
    }

}