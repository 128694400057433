
.business-manage-container.category > .input-button-combo{
    width: 100%;
}
.business-manage-container.category > .input-button-combo > img{
    height: 30px;
    width: 30px;
    background-color: var(--MAIN_COLOR);
    padding: 5px;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}
.business-manage-container.category > .input-button-combo > input{
  width: calc(100% - 60px);
  border: none;
  padding: 8px 0px;
  padding-left: 20px;
  font-size: 1.2em;
  border-radius: 5px 0px 0px 5px;
  box-shadow: none;
  background-color: var(--BG_WHITE_COLOR);
  height: 100%;
}


.category-widget{
  /* background-color: red; */
  height: auto;
  margin-bottom: 20px;
  width: 100%;
}
.category-widget > .header{
  margin-bottom: 10px;
  width: 100%;
}
.category-widget > .header > input{
  width: 100%;
  border: none;
  font-size: 1.4em;
  background-color: var(--BG_WHITE_COLOR);
  padding: 5px 0px 5px 10px;
  border-radius: 10px;
}

.category-widget > .header > img{
    height: 30px;
    width: 30px;
    /* background-color: red; */
    padding: 5px;
    cursor: pointer;
}
.category-widget > .content{
  /* background-color: blue; */
  width: calc(100% + 20px);
  height: auto;
  display: block;
}
.category-widget > .content > .widget{
  height: 200px;
  width: 170px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  float: left;
  background-color: var(--MAIN_COLOR);
  color: white;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}
.category-widget > .content > .widget > img{
  height: 170px;
  width: calc(170px - 2px);
  object-fit: cover;
  margin-bottom: 5px;
  border: thin solid var(--MAIN_COLOR);
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--BG_WHITE_COLOR);
}
.category-widget > .content > .widget > span{
  max-width: 100%;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* background-color: red; */
  
  
  /* display: flex;
  align-items: center;
  justify-content: start; */
}
.category-widget > .content > div.empty{
  /* font-size: 1.5em; */
  height: 170px;
  width: calc(170px - 2px);
  text-align: center;
  background-color: rgba(128, 128, 128, 0.09);
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}



















@media (max-width: 767px) { /* mobile */
  .category-widget > .content{
    width: calc(100%);
  }

  .category-widget > .content > .widget{
      height: 100px;
      width: calc( (100% / 4) - 5px );
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 2.5px;
      float: left;
      background-color: var(--MAIN_COLOR);
      color: white;
      border-radius: 5px;
      overflow: hidden;
  }
    
  .category-widget > .content > .widget > img{
      height: 100%;
      width: calc(100% - 2px);
      object-fit: cover;
      margin-bottom: 0px;
      border-radius: 5px 5px 0px 0px;
      border: thin solid var(--MAIN_COLOR);
      border-bottom: none;
      cursor: pointer;
  }
  .category-widget > .content > .widget > span{
    font-size: 0.7em;
    max-width: 85%;
  }

  .category-widget > .content > div.empty{
      /* font-size: 1.5em; */
    height: 100px;
    width: calc( (100% / 4) - 5px );
    text-align: center;
    background-color: rgba(128, 128, 128, 0.09);
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    font-size: 0.8em;
    border-radius: 5px;
    margin: 0px;
  }

}