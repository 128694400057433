#account{
  /* background-color: red; */
}
.account-container{
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
}

.account-form{
  border: thin solid rgba(128, 128, 128, 0.5);
  margin-top: 20px;
  border-radius: 5px;
}
.account-form > .header{
  width: calc(100% - 20px);
  padding: 10px 0px 10px 20px;
  border-bottom: thin solid rgba(128, 128, 128, 0.5);;
  /* border-top: none; */
  font-size: 1.6em;
  font-weight: bold;
  letter-spacing: 1.2px;
}
.account-form > .content{
  width: calc(100% - 40px);
  padding: 20px;
}
.account-form > .content > .formwidget{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

.account-form > .content > .formwidget > span{
  font-weight: bold;
  letter-spacing: 1.2px;
  margin-bottom: 3px;
}
.account-form > .content > .formwidget input{
  width: calc(100% - 40px);
  padding: 8px 20px;
  font-size: 1.1em;
  letter-spacing: 1.2px;
  border: none;
  background-color: var(--BG_WHITE_COLOR);
  border-radius: 5px;
  border: thin solid transparent;
  font-family: slim;
}
.account-form > .content > p.error{
    color: var(--RED_PALETTE);
}
.account-form > .content > .formwidget input:hover,
.account-form > .content > .formwidget input:active,
.account-form > .content > .formwidget input:focus{
  border: thin solid var(--BTN_COLOR);
}

.account-form > .content > .submit-btn{
  width: 100%;
  padding: 10px 0px;
  background-color: var(--BTN_COLOR);
  margin: 5px 0px;
  border-radius: 10px;
  border: none;
  font-size: 1.4em;
  color: white;
  font-weight: bold;
  letter-spacing: 1.2px;
  margin-top: 20px;
}

.account-form > .content > .mintitle{
  /* font-size: 0.8em; */
  margin-top: 10px;
}
.account-form > .content > .mintitle > a{
  color: var(--TITLE_COLOR);
  cursor: pointer;
}

.account-form > .content > .formwidget > button{
    align-self: center;
    width: 100%;
    margin-bottom: 10px;
}
.account-form > .content > .formwidget > .imgcon{
    height: 50vw;
    width: 50vw;
    align-self: center;
    max-width: 350px;
    max-height: 350px;

    border-radius: 10px;
    overflow: hidden;
    position: relative;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.account-form > .content > .formwidget > .imgcon img{
    width: 100%;
    height: 100%;
    background-color: var(--BG_WHITE_COLOR);
    object-fit: cover;
}
.account-form > .content > .formwidget > .imgcon span{
    width: 100%;
    position: absolute;
    bottom: 0px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-shadow: 1px 1px 0px rgb(130, 130, 130);
}



.account-form > .content > .formwidget > .username-con{
  position: relative;
  width: 100%;
}
.account-form > .content > .formwidget > .username-con input{
  padding-left: 30px;
}
.account-form > .content > .formwidget > .username-con .img{
  position: absolute;
  left: 10px;
  font-size: 1.2em;
  font-family: avant-garde-md;
}