@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
:root{
  --MAX_WIDTH: 960px;

  
  /* --MAIN_COLOR: #fdcb30; */
  /* --MAIN_COLOR: #557C55; */
  --MAIN_COLOR: #C80F2E;
  
  /* --MAIN_COLOR_HALF: rgba(253, 202, 48, 0.5); */
  --MAIN_COLOR_HALF: rgba(85, 124, 85, 0.5);
  
  
  --SUB_COLOR: #fba516;
  --SHADE_COLOR: #f58511;
  

  --BTN_COLOR: #C80F2E;
  /* --BTN_COLOR: #718D71; */
  /* --BTN_COLOR: #ef1113; */
  /* --BTN_COLOR_HOVER: rgb(194, 151, 44); */
  --BTN_COLOR_HOVER: hsla(350, 86%, 42%, 0.5);
  --MESSAGE_MY_COLOR: #A6CF98;

  

  /* --BG_WHITE_COLOR: #FEFEFE; */
  --BG_WHITE_COLOR: #f7f7f7;

  
  --TEXT_COLOR: white;
  --TITLE_COLOR: #f58511;

  --ORANGE_PALETTE: #d15421;
  --GREEN_PALETTE: #7FA559;
  --PINK_PALETTE: #BA447F;
  --RED_PALETTE: #B24C4C;
  --YELLOW_PALETTE: #B2B24C;
  --PURPLE_PALETTE: #5959A5;
  --BLUE_PALETTE: #59A5A5;


  
}

@font-face {
  font-family: 'avant-garde-lg';
  src: url(/static/media/AvantGardeLG.959475bd.ttf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'avant-garde-md';
  src: url(/static/media/AvantGardeMD.57e5551b.otf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'slim';
  src: url(/static/media/slim.43eb9ab9.woff2);
  font-weight: normal;
  font-style: normal;
}


/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}


#root::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* position: relative;
  right: 20px; */
}
/* Track */
#root::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.07);  */
  background: #C80F2E;
  background: var(--BTN_COLOR); 
  border-radius: 5px;
}
/* Handle */
#root::-webkit-scrollbar-thumb {
  /* background: #888;  */
  background: #C80F2E;
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
/* Handle on hover */
#root::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
  background: hsla(350, 86%, 42%, 0.5);
  background: var(--BTN_COLOR_HOVER); 
}



.flex-row-center-center{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flex-row-center-start{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-row-center-end{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.flex-row-center-between{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.flex-row-center-around{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.flex-row-center-even{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.flex-row-start-center{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.flex-row-start-start{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-row-start-end{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-row-start-between{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-row-start-around{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}
.flex-row-start-even{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.flex-row-end-center{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.flex-row-end-start{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.flex-row-end-end{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-row-end-between{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-row-end-around{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}
.flex-row-end-even{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
}



.flex-col-center-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-col-center-start{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.flex-col-center-end{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.flex-col-center-between{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.flex-col-center-around{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.flex-col-center-even{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.flex-col-start-center{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.flex-col-start-start{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-col-start-end{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-col-start-between{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-col-start-around{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.flex-col-start-even{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.flex-col-end-center{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.flex-col-end-start{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.flex-col-end-end{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-col-end-between{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-col-end-around{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}
.flex-col-end-even{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}






button, .btn{
  background-color: #C80F2E;
  background-color: var(--BTN_COLOR);
  color: white;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
}
button:active{
  background-color: hsla(350, 86%, 42%, 0.5);
  background-color: var(--BTN_COLOR_HOVER);
}


progress {
  border-radius: 3px; 
  width: 80%;
}
progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  border-radius: 0px 5px 5px 0px;
}
progress::-webkit-progress-value {
  background-color: goldenrod;
  border-radius: 0px 5px 5px 0px;
}
progress::-moz-progress-bar {
/* style rules */
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  border-radius: 0px 5px 5px 0px;
}
progress::-moz-progress-value {
  background-color: goldenrod;
  border-radius: 0px 5px 5px 0px;
}


*:focus {
  outline: 0;
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


*[message] {
  position: relative;
  color: #00F;
  cursor: help;
}

*[message]:hover::after,
*[message]:focus::after {
  content: attr(message);
  position: absolute;
  left: 0;
  top: 24px;
  min-width: 200px;
  border-radius: 10px;
  background-color: #f7f7f7;
  background-color: var(--BG_WHITE_COLOR);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  padding: 12px;
  color: #000000;
  font-size: 14px;
  z-index: 999999;
  font-family: slim;
  letter-spacing: 1.2px;
  line-height: 1.3em;
}




/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /* position: relative;
  right: 20px; */
}
/* Track */
::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.07);  */
  background: #f7f7f7;
  background: var(--BG_WHITE_COLOR); 
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888;  */
  background: #C80F2E;
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
  background: #C80F2E;
  background: var(--BTN_COLOR); 
}




.pdftest{
  height: 100%;
  width: 100%;
  text-align: center;
}
.pdftestError{
  padding: 10px;
}

.error{
  color: #B24C4C;
  color: var(--RED_PALETTE);
}
.alert{
  color: #B24C4C;
  color: var(--RED_PALETTE);
}.info{
  color: #59A5A5;
  color: var(--BLUE_PALETTE);
}

.w100{
  width: 100%;
}

.webcat{
  /* background-color: black; */
  width: 100%;
}
.webcat > *{
  width: 100%;
}
.toast-container{
    position: fixed;
    bottom: 10vh;
    width: 70vw;
    max-width: 70vw;
    left: calc( 30vw / 2 );
    z-index: 99999;
}
.toast-container span{
    max-width: var(--MAX_WIDTH);
    background-color: var(--MAIN_COLOR);
    /* background-color: rgb(97, 97, 97); */
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    letter-spacing: 1.2px;
    word-spacing: 2px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    font-size: 0.8em;
    text-align: center;
    text-overflow: ellipsis;
}


#global-prompt{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}
#global-prompt > .container{
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
    background-color: var(--MAIN_COLOR);
    border-radius: 5px;
    color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    text-align: center;
    text-overflow: ellipsis;
    padding: 20px 10px;
}
#global-prompt > .container > .password{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2); */
    border-radius: 5px;
    margin-top: 10px;
}
#global-prompt > .container > .password > div{
    margin-top: 10px;
}
#global-prompt > .container > .password button{
    width: calc(50% - 10px);
    font-size: 1.2em;
    border-radius: 5px;
    box-shadow: none;
}
#global-prompt > .container > .password input{
    width: calc(100% - 20px);
    border: none;
    padding: 5px 0px;
    padding-left: 20px;
    font-size: 1.2em;
    border-radius: 5px;
    box-shadow: none;
    background-color: var(--BG_WHITE_COLOR);
    height: 100%;
}


#global-prompt > .container > .choice{
    width: 100%;
    margin-top: 10px;
}




  @media (max-width: 768px) { /* mobile */
    :root{
        --HTITLE_SIZE: 3vw;
       
    }

    .toast-container{
        position: fixed;
        bottom: 20px;
        width: 70vw;
        max-width: 70vw;
        left: calc( 30vw / 2 );
    }
    .toast-container span{
        background-color: var(--MAIN_COLOR);
        padding: 5px 10px;
        border-radius: 5px;
        color: white;
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
        letter-spacing: 1.2px;
        word-spacing: 2px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        font-size: 0.8em;
        text-align: center;
    }


    
    
    
    

}

.custom-form .field{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.custom-form .field > div{
    width: 100%;
}
.custom-form .field > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
}
.custom-form .field > span{
    width: 100%;
    text-align: start;
}
.custom-form .field > span > .red{
    color: red;
}
.custom-form .field > span > .purple{
    color: purple;
}
.custom-form .field > span > .premium{
    color: goldenrod;
}
/* .custom-form .field > span > .right{
color: red;
} */
.custom-form .field > iframe{
    width: 100%;
    height: 300px;
}
.custom-form .field > select{
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
}
.custom-form .field > textarea{
    width: calc(100% - 15px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
}
.custom-form > button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
}
.custom-form * button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
}
.custom-form .input-button-combo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
}
.custom-form .input-button-combo > button{
    width: 30%;
    font-size: 1.2em;
    border-radius: 0px 5px 5px 0px;
    box-shadow: none;
}
.custom-form .input-button-combo > input{
    width: calc(70% - 20px);
    border: none;
    padding: 5px 0px;
    padding-left: 20px;
    font-size: 1.2em;
    border-radius: 5px 0px 0px 5px;
    box-shadow: none;
    background-color: var(--BG_WHITE_COLOR);
    height: 100%;
}






.custom-form > .field input:active,
.custom-form > .field input:focus{
    /* border: thick solid blue; */
    border: none;
    outline: none;
}

.custom-form > .title{
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}



.custom-form > .divider-message{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    position: relative;
    z-index: inherit;
}
.custom-form > .divider-message > hr{
    width: 100%;
    position: absolute;
    z-index: -5;
}
.custom-form > .divider-message > span{
    /* z-index: 5; */
    background-color: white;
    padding: 0px 10px;
    font-size: 0.8em;
}
.login-container{
  width: 100%;
  /* margin: 10px 0px; */
}
.login-container form{
  width: calc(100% - 40px);
  max-width: 450px;
}

.login-google{
  background-color: #4286f6;
}
.login-facebook{
  background-color: #3e5b99;
}
.login-apple{
  background-color: black;
}
.login-container > button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: calc(100% - 40px);
  max-width: 450px;
  font-size: 1.2em;
  margin-top: 10px;
}

.login-container button:active{
  background-color: grey;
}

.login-container p.msg{
  color: grey;
}
.login-container p.msg > a{
  color: var(--MAIN_COLOR);
  font-weight: bold;
  letter-spacing: 1.2px;
  text-decoration: none;
}
.signup-container{
  width: 100%;
  /* margin: 10px 0px; */
}
.signup-container form{
  width: calc(100% - 40px);
  max-width: 450px;
}

.signup-google{
  background-color: #4286f6;
}
.signup-facebook{
  background-color: #3e5b99;
}
.signup-apple{
  background-color: black;
}
.signup-container > button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: calc(100% - 40px);
  max-width: 450px;
  font-size: 1.2em;
  margin-top: 10px;
}

.signup-container button:active{
  background-color: grey;
}

.signup-container p.msg{
  color: grey;
}
.signup-container p.msg > a{
  color: var(--MAIN_COLOR);
  font-weight: bold;
  letter-spacing: 1.2px;
  text-decoration: none;
}
/* navbar styles */
.navbar {
  width: calc(100%);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.navbar > .container{
  width: calc(100% - 20px);
  padding: 10px 10px;
  /* max-width: var(--MAX_WIDTH); */
  /* background-color: red; */
  position: relative;
  
}
.navbar > .container img{
  height: 40px;
  width: 40px;
  cursor: pointer;
  object-fit: cover;
}
.navbar > .container .logo{
  margin-left: 10px;
}
.navbar > .container .icon{
  height: 50px;
  width: 50px;
  /* position: relative;
  bottom: -6px; */
  object-fit: contain;
}
.navbar > .container .buttonlogo{
  margin-left: 10px;
  height: 50px;
  width: 100px;
  position: relative;
  bottom: -2px;
  object-fit: contain;
}
.navbar > .container .brandname{
  margin-left: 10px;
  font-size: 1.2em;
  font-family: 'avant-garde-lg';
  color: var(--MAIN_COLOR);
  letter-spacing: 4px;
  text-decoration: none;
}
.navbar > .container > .left{
  text-decoration: none;
  margin-left: 10px;
}
.navbar > .container .right{
  /* height: 100%; */
  width: auto;
  position: relative;
  /* background-color: red; */
}
.navbar > .container > .right img{
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
  object-fit: cover;
}
.navbar > .container .userprofile{
  /* background-color: red; */
  /* height: 50px;
  width: 50px; */
  margin-left: 10px;
}
.navbar > .container .username{
  /* position: absolute; */
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--MAIN_COLOR);
  font-family: 'avant-garde-lg';
  font-weight: bold;
  font-size: 1.2em;
  color: white;
  letter-spacing: 2px;
  border: 1px solid white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
  cursor: pointer;
}


.navbar > .container > .right-popup{
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  padding: 5px 10px;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
}
.navbar > .container > .right-popup img{
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-right: 10px;
  object-fit: cover;
}



.navbar-logo{
  transform: scale(0.8);
  /* height: 30px;
  width: 30px;
  object-fit: cover; */
}

.navbar-buttonlogo{
  /* margin-left: 10px; */
  height: 50px;
  width: 120px;
  min-width: 120px;
  position: relative;
  bottom: -2px;
  object-fit: contain;
  transform: scale(0.8);
  /* background-color: red; */
}

.navbar-search{
  position: relative;
  border: none;
  border-radius: 10px;
}
.navbar-search:focus,
.navbar-search:active,
.navbar-search:hover{
  border: thin solid var(--BTN_COLOR);
}
.navbar-search > input{
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  font-size: 1em;
  padding: 10px 15px;
  border-radius: 10px;
  padding-right: 40px;
}
.navbar-search > svg{
  position: absolute;
  right: 10px;
  cursor: pointer;
}







.navpop{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 9000;
}
.navpop > .container{
  height: 100vh;
  max-height: 100vh;
  width: 350px;
  
  background-color: white;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  overflow-y: auto;
}

.navpop > .container > .close{
  height: 40px;
  width: calc(100% - 20px);
  padding: 0px 10px;
  font-size: 1.2em;
  /* background-color: red; */
  border-bottom: thin solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}
.navpop > .container > .close > img{
  height: 30px;
  width: 30px;
  cursor: pointer;
}
/* .navpop > .container > .close:hover > i{
  font-size: 1.4em;
} */


.navpop > .container > .widget-con{
  height: auto;
  /* max-height: calc( 100% - 40px ); */
  width: calc(100%);
  /* padding-left: 20px; */
  /* background-color: red; */
}

.navpop > .container > .widget-con > .widget{
  width: calc(100% - 40px);
  padding: 15px 20px;
  margin-right: 20px;
  position: relative;
  /* background-color: red; */
  cursor: pointer;
  margin-bottom: 1px;
  text-decoration: none;
  color: black;
}
.navpop > .container > .widget-con > .widget:hover{
  background-color: var(--BG_WHITE_COLOR);
}
.navpop > .container > .widget-con.super > .widget{
  font-weight: bold;
}
.navpop > .container > .widget-con> .widget > img{
  margin-right: 20px;
  height: 30px;
  width: 30px;
}
.navpop > .container > .widget-con > .widget > span{
  font-family: avant-garde-md;
  cursor: pointer;
}
.navpop > .container > .widget-con.super > .widget > span{
  letter-spacing: 0.1em;
}
.navpop > .container > .widget-con> .widget > hr{
  height: 0.001px;
  width: calc(100% - 30px);
  position: absolute;
  bottom: -10px;
  left: 20px;
  cursor: pointer;
}


#svg_logo{
  margin-left: 10px;
  width: 120px;
  cursor: pointer;
}

@keyframes float1 {
  0% {
    transform: translatey(0px);
  }
  3% {
    transform: translatey(-20px);
  }
  9% {
    transform: translatey(20px);
  }
  12% {
    transform: translatey(0px);
  }
}


@media (max-width: 768px) { /* mobile */
  .navpop > .container{
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    overflow-y: auto;
  }

  .navbar > .container > .left a{
    margin-left: 10px;
  }
  .navbar-search{
    width: 65%;
    position: relative;
    border: none;
    border-radius: 10px;
  }
  .navbar-search > input{
    width: 100%;
  }

}
.home-wrapper{
    width: 100%;
    height: auto;
}

.home-container{
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
    height: auto;
    padding: 10px;
    /* background-color: red; */
}

.category-widget-wrapper{
    width: 100%;
    max-width: var(--MAX_WIDTH);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.category-widget-wrapper > .scroller{
    position: absolute;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    cursor: pointer;
}
.category-widget-wrapper > .scroller.left{
    left: -15px;
}
.category-widget-wrapper > .scroller.right{
    right: -15px;
}
.category-widget{
  width: 100%;
  height: 100px;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
}
.category-widget > .widget{
  width: auto;
  min-width: 80px;
  height: 100%;
  margin-bottom: 10px;
  padding: 0px 5px;
  margin: 0px 10px 10px 10px;
  /* background-color: red; */
  cursor: pointer;
}
.category-widget > .widget.active > span{
    width: auto;
    text-align: center;
    background-color: var(--MAIN_COLOR);
    border-radius: 5px;
    color: white;
    padding: 0px 10px;
}
.category-widget > .widget > img{
  height: 70px;
  width: 70px;
  object-fit: cover;
}
.category-widget > .widget span{
  cursor: pointer;
  white-space: nowrap;
}
.category-widget > .widget > img{
  cursor: pointer;
}
.category-widget > .widget > img *{
  cursor: pointer;
}


.category-widget::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}



.filter-widget{
    width: 100%;
    height: auto;
    margin-top: 10px;
    /* background-color: green; */
    position: relative;
    overflow-x: auto;
    /* overflow-y: hidden; */
}
.filter-widget > .widget{
    background-color: var(--MAIN_COLOR);
    width: calc(50% - 35px);
    border-radius: 15px;
    color: white;
    cursor: pointer;
    padding: 5px 15px;
}
.filter-widget > .widget span{
    cursor: pointer;
}
.filter-widget > .widget > svg{
    margin: 0px 5px;
    cursor: pointer;
    font-size: 1.2em;
    margin-right: 10px;
}
.filter-widget > .widget > span{
    width: 100%;
}

.react-select{
    width: 100%;
    z-index: 10;
}


.home-popup{
  height: auto;
  width: calc(100%);
  /* min-width: 350px; */
  padding: 20px;
  background-color: white;
  /* position: fixed; */
  /* top: 37px; */
  /* left: 0px; */
  border-radius: 10px;
  /* box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2); */
  color: black;
  z-index: 9;
  margin-top: 10px;
}
.home-popup.hidden{
  display: none;
}
.home-popup > .title{
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 5px;
  letter-spacing: 1.2px;
}
.home-popup > .subtitle{
  color: grey;
  margin-bottom: 20px;
}
.home-popup > hr{
  width: calc(100% - 0px);
}
.home-popup > .action{
  width: 100%;
  cursor: pointer;
}
.home-popup > .action > .button{
  margin-left: 20px;
}

.home-popup > .rating{
    width: 350px;
    align-self: center;
}
.home-popup > .rating > .ratingwidget{
  margin: 0px 15px;
  cursor: pointer;
}
.home-popup > .rating > .ratingwidget > svg{
  margin-bottom: 5px;
  color: grey;
}
.home-popup > .rating > .ratingwidget > svg.active{
  color: var(--BTN_COLOR);
}


.home-popup > .delivery{
    width: 100%;
    /* background-color: red; */
}
.home-popup > .delivery > .react-select{
    width: 100%;
    color: black;
    text-align: start;
    cursor: pointer;
    min-width: 350px;
}








.promotions-widget::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
.promotions-widget::-webkit-scrollbar-track {
  background: white; 
  border-radius: 5px;
}
.promotions-widget::-webkit-scrollbar-thumb {
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
.promotions-widget::-webkit-scrollbar-thumb:hover {
  background: var(--BTN_COLOR); 
}

.promotions-widget{
  width: 100%;
  height: 160px;
  max-width: 100%;
  overflow: hidden;
  overflow-x: scroll;
}
.promotions-widget > .widget{
  width: calc(50% - 10px);
  min-width: calc(50% - 10px);
  height: calc(100% - 10px);
  /* background-color: red; */
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.promotions-widget > .widget > .fgcolor{
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(110deg, var(--MAIN_COLOR) 65%, rgba(0, 0, 0, 0) 65%);
  z-index: 2;
  cursor: pointer;
}
.promotions-widget > .widget > .fgcolor > .title{
  max-width: 65%;
  /* font-family: avant-garde-md; */
  padding: 10px;
  font-weight: 500;
  /* letter-spacing: 1.1px; */
  color: white;
  cursor: pointer;
}
.promotions-widget > .widget > .fgcolor > .subtitle{
  max-width: 65%;
  padding: 0px 10px;
  /* font-weight: bold; */
  color: white;
  cursor: pointer;
}
.promotions-widget > .widget > img{
  position: absolute;
  right: 0px;
  bottom: 0px;
  max-height: 100%;
  object-fit: cover;
  z-index: 1;
  cursor: pointer;
  background-color: var(--BG_WHITE_COLOR);
}



.restaurants-widget{
  width: 100%;
  height: auto;
}
.restaurants-widget > .header{
  width: 100%;
  height: auto;
}
.restaurants-widget > .header > .title{
  font-size: 1.2em;
  /* font-weight: bold; */
  /* font-family: avant-garde-md; */
}
.restaurants-widget > .header > .actions > svg{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  padding: 5px;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.restaurants-widget > .header > .actions > svg.disabled{
  color: rgb(179, 179, 179);
  background-color: rgb(232, 232, 232);
}
.restaurants-widget > .header > .actions > svg.enabled{
  color: black;
  background-color: rgb(211, 211, 211);
}
.restaurants-widget > .header > .actions > span{
  margin-right: 20px;
  cursor: pointer;
  /* font-weight: bold; */
  font-size: 0.8em;
}
.restaurants-widget > .content{
  width: 100%;
  height: auto;
  /* min-height: 160px; */
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  /* background-color: red; */
  margin-top: 10px;
}
/* width */
.restaurants-widget > .content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}


.restaurant-card{
  width: calc( (100% / 3) - 10px );
  min-width: calc( (100% / 3) - 10px );
  height: auto;
  /* margin-right: 10px;
  margin-bottom: 10px; */
  margin: 0px 10px 10px 10px;
  /* background-color: blue; */
  text-decoration: none;
  color: black;
}
.restaurant-card > img{
  height: 160px;
  width: calc(100%);
  object-fit: cover;
  border-radius: 10px;
  background-color: var(--BG_WHITE_COLOR);
}
.restaurant-card > .title{
  font-size: 0.9em;
  font-weight: 500;
  /* font-family: avant-garde-md; */
  width: 100%;
  text-align: start;
  margin: 5px 0px;
}
.restaurant-card > .subtitle{
  width: 100%;
  color: grey;
}























@media (max-width: 767px) { /* mobile */

    .promotions-widget > .widget{
        width: calc(1000% - 10px);
        min-width: calc(100% - 10px);
    }

    .restaurant-card{
        width: calc( 100% - 20px );
        min-width: calc( 100% - 20px );
        height: auto;
        /* margin-right: 10px;
        margin-bottom: 10px; */
        margin: 10px;
        padding-bottom: 10px;
        /* background-color: blue; */
        border-bottom: thin solid rgb(200, 200, 200, 0.2);
    }

    .home-popup > .rating{
        width: 90%;
    }
    .home-popup > .delivery > .react-select{
        min-width: 90%;
    }

}
.modal-container-backdrop{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100000000000;
    background-color: rgba(255, 255, 255, 0.164);
    height: 100vh;
    width: 100vw;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container{
    max-width: 50vw;
    min-width: 250px;
    max-height: 80vh;
    padding: 30px 50px;
    background-color: white;
    /* margin-top: 0vh; */
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;

    overflow: hidden;
    overflow-y: auto;
}
.modal-container > .modal-close{
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.modal-container form{
    min-width: 500px;
}
.modal-container p{
    width: 100%;
    text-align: start;
    margin-bottom: 10px;
}
.modal-container span.title{
  width: 100%;
  text-align: start;
  margin-bottom: 10px;
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modal-container span.title > *{
  padding-left: 5px;
  font-size: 0.8em;
}
.modal-container .field{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .modal-container .field > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  .modal-container .field > span{
      width: 100%;
      text-align: start;
  }
  .modal-container .field > span > .red{
    color: red;
  }
  .modal-container .field > span > .purple{
    color: purple;
  }
  .modal-container .field > span > .premium{
    color: goldenrod;
  }
  /* .modal-container .field > span > .right{
    color: red;
  } */
  .modal-container .field > iframe{
    width: 100%;
    height: 300px;
  }
  .modal-container .field > select{
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
  }
  .modal-container > button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
  }
  .modal-container * button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
  }
  .modal-container .input-button-combo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .modal-container .input-button-combo > button{
    width: 30%;
    font-size: 1.2em;
    border-radius: 0px 5px 5px 0px;
    box-shadow: none;
  }
  .modal-container .input-button-combo > input{
    width: calc(70% - 20px);
    border: none;
    padding: 5px 0px;
    padding-left: 20px;
    font-size: 1.2em;
    border-radius: 5px 0px 0px 5px;
    box-shadow: none;
    background-color: var(--BG_WHITE_COLOR);
    height: 100%;
  }
  .modal-container .singleImage{
    height: auto;
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
  }
  .modal-container .singleImage img{
    max-width: 100%;
    max-height: 60vh;
  }


  .modal-container .field-checkbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: between;
    width: 100%;
    /* background-color: red; */
  }
  .modal-container .field-checkbox > label > input{
    min-width: 20px;
  }
  .modal-container .field-checkbox > label{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: blue; */
  }
  .modal-container .field-checkbox > label > span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    position: relative;
    /* background-color: red; */
  }
  .modal-container .field-checkbox > label > span > label{
    width: auto;
    font-size: 0.9em;
    position: absolute;
    right: 0px;
    /* height: 100%; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* background-color: purple; */
  }
  .modal-container .field-checkbox > p{
    margin-left: 15%;
    color: grey;
  }

  @media (max-width: 768px) { /* mobile */
    :root{
        --HTITLE_SIZE: 3vw;
       
    }

    .modal-container{
      max-width: calc(10vw - 150px);
      min-width: 75vw;
      padding: 30px 30px;
      background-color: white;
      margin-top: 10vh;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
      text-align: center;
      position: relative;
  }
  .modal-container form{
      min-width: 100%;
  }
  .modal-container .field-checkbox > p{
    margin-left: 30%;
  }


    
    
    
    

}
.contact-container{
    width: 100%;
    /* background-color: red; */
    margin: 20px 0px;
}

.contact-container h1,
.contact-container h2,
.contact-container h3,
.contact-container h4,
.contact-container h5,
.contact-container h6{
    padding: 0px;
    margin: 0px 20px;
    /* background-color: red; */
}

.contact-container p{
    width: calc(100% - 40px);
    padding: 10px 20px;
    text-align: center;
}
.contact-container b{

}
.contact-container h1{
    width: 100%;
    text-align: center;
    align-self: center;
    
}


.contact-container form{
    width: calc(100% - 40px);
    max-width: 450px;
}
.contact-container input,
.contact-container textarea{
    width: calc(100% - 40px);
    padding: 5px 10px;
}
#account{
  /* background-color: red; */
}
.account-container{
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
}

.account-form{
  border: thin solid rgba(128, 128, 128, 0.5);
  margin-top: 20px;
  border-radius: 5px;
}
.account-form > .header{
  width: calc(100% - 20px);
  padding: 10px 0px 10px 20px;
  border-bottom: thin solid rgba(128, 128, 128, 0.5);;
  /* border-top: none; */
  font-size: 1.6em;
  font-weight: bold;
  letter-spacing: 1.2px;
}
.account-form > .content{
  width: calc(100% - 40px);
  padding: 20px;
}
.account-form > .content > .formwidget{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 10px;
}

.account-form > .content > .formwidget > span{
  font-weight: bold;
  letter-spacing: 1.2px;
  margin-bottom: 3px;
}
.account-form > .content > .formwidget input{
  width: calc(100% - 40px);
  padding: 8px 20px;
  font-size: 1.1em;
  letter-spacing: 1.2px;
  border: none;
  background-color: var(--BG_WHITE_COLOR);
  border-radius: 5px;
  border: thin solid transparent;
  font-family: slim;
}
.account-form > .content > p.error{
    color: var(--RED_PALETTE);
}
.account-form > .content > .formwidget input:hover,
.account-form > .content > .formwidget input:active,
.account-form > .content > .formwidget input:focus{
  border: thin solid var(--BTN_COLOR);
}

.account-form > .content > .submit-btn{
  width: 100%;
  padding: 10px 0px;
  background-color: var(--BTN_COLOR);
  margin: 5px 0px;
  border-radius: 10px;
  border: none;
  font-size: 1.4em;
  color: white;
  font-weight: bold;
  letter-spacing: 1.2px;
  margin-top: 20px;
}

.account-form > .content > .mintitle{
  /* font-size: 0.8em; */
  margin-top: 10px;
}
.account-form > .content > .mintitle > a{
  color: var(--TITLE_COLOR);
  cursor: pointer;
}

.account-form > .content > .formwidget > button{
    align-self: center;
    width: 100%;
    margin-bottom: 10px;
}
.account-form > .content > .formwidget > .imgcon{
    height: 50vw;
    width: 50vw;
    align-self: center;
    max-width: 350px;
    max-height: 350px;

    border-radius: 10px;
    overflow: hidden;
    position: relative;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.account-form > .content > .formwidget > .imgcon img{
    width: 100%;
    height: 100%;
    background-color: var(--BG_WHITE_COLOR);
    object-fit: cover;
}
.account-form > .content > .formwidget > .imgcon span{
    width: 100%;
    position: absolute;
    bottom: 0px;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-shadow: 1px 1px 0px rgb(130, 130, 130);
}



.account-form > .content > .formwidget > .username-con{
  position: relative;
  width: 100%;
}
.account-form > .content > .formwidget > .username-con input{
  padding-left: 30px;
}
.account-form > .content > .formwidget > .username-con .img{
  position: absolute;
  left: 10px;
  font-size: 1.2em;
  font-family: avant-garde-md;
}
/* .business{
    width: 100vw;
} */
.business-container{
  width: calc(100vw);
  /* background-color: blue; */
}
.business-widget-container{
  width: calc(100% - 20px);
  max-width: var(--MAX_WIDTH);
  position: relative;
  border-bottom: thin solid rgba(128, 128, 128, 0.2);
}
.business-widget,
.business-widget-add{
  width: calc(100% - 20px);
  position: relative;
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  /* background-color: red; */
}
.business-widget *{
  cursor: pointer;
}
/* .business-widget span,
.business-widget img{
  cursor: pointer;
} */
.business-widget-container:hover{
  background-color: var(--BG_WHITE_COLOR);
}
.business-widget > img{
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.business-widget-container > img.delete{
  position: absolute;
  top: 0px;
  right: 0px;
  height: 20px;
  width: 20px;
  padding: 10px;
  background-color: white;
}
.business-widget-container:hover > img.delete{
  background-color: var(--BG_WHITE_COLOR);
}
.business-widget-container > .subtitle{
  font-size: 0.7em;
  color: grey;
}

.business-widget > i{
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 1.2em;
  padding: 10px;
  /* background-color: red; */
}


.business-widget > .details{
  margin-left: 10px;
  /* cursor: pointer; */
  /* background-color: red; */
  width: calc(100% - 100px);
  max-width: calc(100% - 100px);
}
.business-widget > .details > .store-name{
    max-width: calc(100% - 15px);
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 1.2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    float: left;

}
.business-widget > .details > .text{
  /* margin-bottom: 5px; */
  /* font-size: 0.9em; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    float: left;
}
.business-widget > .details > .text > i{
    font-size: 0.8em;
    margin-left: 3px;
    color: var(--BTN_COLOR);
}

.business-widget-add >.add-new-business{
  width: calc(100% - 40px);
  max-width: var(--MAX_WIDTH);
}

.business-widget-add >.add-new-business > a{
  padding: 3px 20px;
  background-color: var(--MAIN_COLOR);
  display: flex;
  align-items: center;
  justify-content: center;
}
.business-widget-add >.add-new-business > a > img{
  height: 40px;
}
.business-widget-add >.add-new-business > input{
  height: 100%;
  width: 100%;
  padding: 10px 0px 10px 10px;
  border: none;
  font-size: 1.4em;
  background-color: var(--BG_WHITE_COLOR);
}
.business-widget-add > .subtitle{
  font-size: 0.7em;
  text-align: center;
  margin-top: 5px;
}
.business-widget-add > .title > .subtitle{
  font-size: 0.7em;
  text-align: center;
}
.business-widget-popup{
  width: 100%;
  max-width: var(--MAX_WIDTH);
  margin-bottom: 10px;
  /* background-color: red; */
}
.business-widget-popup > .delete{
  background-color: var(--RED_PALETTE);
  background-color: var(--MAIN_COLOR);
  color: white;
  padding: 5px 5px 5px 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
}
.business-widget-popup > .delete > img{
  height: 20px;
  width: 20px;
  padding: 3px;
  background-color: whitesmoke;
  margin-left: 5px;
  border-radius: 5px;
}
.business-widget-popup > .change{
  background-color: var(--BLUE_PALETTE);
  background-color: var(--MAIN_COLOR);
  color: white;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
  max-width: 65%;
}
.business-widget-popup > .change > img{
  height: 20px;
  width: 20px;
  padding: 3px;
  background-color: whitesmoke;
  /* background-color: var(--GREEN_PALETTE); */
  /* margin-left: 5px; */
  border-radius: 0px 5px 5px 0px;
  border-radius: 5px;
}
.business-widget-popup > .change > img.red{
  background-color: var(--RED_PALETTE);
}
.business-widget-popup > .change > img.green{
  background-color: var(--GREEN_PALETTE);
}
.business-widget-popup > .change > img.yellow{
  background-color: var(--YELLOW_PALETTE);
}
.business-widget-popup > .change > input{
  border: none;
  background-color: var(--BG_WHITE_COLOR);
  background-color: var(--BLUE_PALETTE);
  background-color: var(--MAIN_COLOR);
  color: white;
  border-radius: 5px 0px 0px 5px;
  padding: 5.5px 0px;
  padding-left: 10px;
}













.create-business-protocol{
  width: 100%;
  /* background-color: red; */
}
.create-business-protocol .container{
  width: calc(100% - 20px);
  max-width: var(--MAX_WIDTH);
}
.create-business-protocol .container > h1{
  align-self: center;
  text-align: center;
}
.create-business-protocol .container > .subtitle{
  font-size: 0.8em;
}
.create-business-protocol .container > .title{
  font-size: 1.2em;
  font-weight: bold;
}

.create-business-protocol .container > label{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
}
.create-business-protocol .container > label > input{
  position: relative;
  bottom: -3px;
  min-width: 20px
}
.create-business-protocol .container > label > img{
  margin: 0px 10px;
}
.create-business-protocol .container > button{
  align-self: center;
  font-weight: bold;
  font-size: 1.2em;
}
/* .business-manage-main-container{

} */

#business-manage{
    /* background-color: red; */
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
  }
  
  
  #business-manage .field-single{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  #business-manage .field-single > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  #business-manage .field-single > textarea{
    width: calc(100% - 15px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
  }
  
  
  #business-manage .field-double{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  #business-manage .field-double input{
    width: calc(50% - 25px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  #business-manage .field-double textarea{
    width: calc(50% - 25px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
  }
  #business-manage button.submit{
    width: 100%;
    font-size: 1.4em;
  }
  
  #business-manage .field > div.iframe{
    width: 100%;
    height: 300px;
    position: relative;
  }
  #business-manage .field > div.iframe > i{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    z-index: 100;
    font-size: 2em;
    color: var(--BTN_COLOR);
    text-shadow: 1px 1px 3px black;
  }
  #business-manage .field > .input-button-combo{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    /* font-size: 1.2em; */
    background-color: var(--BG_WHITE_COLOR);
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }
  #business-manage .field > .input-button-combo > input{
    width: calc( 100% - 100px );
    height: 100%;
    border: none;
    background-color: var(--BG_WHITE_COLOR);
    font-size: 1.2em;
  }
  #business-manage .field > .input-button-combo > button{
    width: 100px;
    font-size: 1.2em;
    padding: 0px;
  }
  
  #business-manage > .nav{
      width: 100%;
      z-index: 1;
  }
  #business-manage > .nav > span{
    width: calc( (100% / 5) - 5px );
    background-color: var(--BTN_COLOR);
    /* font-size: 1em; */
    font-weight: bold;
    padding: 5px 0px;
    text-align: center;
    color: var(--TEXT_COLOR);
    cursor: pointer;
    border-radius: 5px;
  }
  #business-manage > .nav > a.active{
    /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2); */
    font-size: 1.2em;
    border-bottom: thin solid black;
  }
  #business-manage > .nav > a:active{
    background-color: var(--BTN_COLOR_HOVER);
  }
  #business-manage > .nav.mobile{
      position: fixed;
      bottom: 0px;
      background-color: white;
      padding: 10px;
  }
  #business-manage > .nav.mobile > img{
    background-color: var(--MAIN_COLOR);
    /* background-color: var(--BG_WHITE_COLOR); */
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    height: 30px;
    width: 30px;
  }
  #business-manage > .nav.mobile > img.super{
    height: 50px;
    width: 50px;
    padding: 5px;
  }
  #business-manage > .nav.mobile > img.active{
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
    
  }
  
 .business-manage-container{
    width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
    margin-bottom: 100px;
  }
  
  
.business-manage-container > .store-profile{
  height: 200px;
  width: 100%;
  position: relative;
  border-radius: 15px;
  
}
.business-manage-container > .store-profile > .store-background{
  height: 100%;
  width: 100%;
  /* position: absolute; */
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
    overflow: hidden;
}
.business-manage-container > .store-profile > .store-background > img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  cursor: pointer;
  border-radius: 10px;
}
.business-manage-container > .store-profile > .store-background > .fg{
  height: 50px;
  width: 100%;
  z-index: 1;
  background-color: rgba(128, 128, 128, 0.5);
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}
.business-manage-container > .store-profile > .store-image{
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: -25px;
  left: 10px;
  background-color: white;
  z-index: 5;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}
.business-manage-container > .store-profile > .store-image > img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  cursor: pointer;
}
.business-manage-container > .store-profile > .store-image > .fg{
  height: 25px;
  width: 100%;
  z-index: 1;
  background-color: rgba(128, 128, 128, 0.5);
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  color: white;
  /* font-size: 1.2em; */
  font-weight: bold;
}

.business-manage-container > .store-name{
  margin-top: 10px;
  width: calc(100% - 100px);
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
  border: none;
  background-color: transparent;
  /* background-color: var(--BG_WHITE_COLOR); */

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  float: left;
}
.business-manage-container > .field{
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 10px;
}
.business-manage-container > .field > input{
  width: calc(100% - 15px);
  padding: 5px;
  padding-left: 10px;
  font-size: 1.2em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  /* border-bottom: 1px solid black; */
  border-radius: 5px;
}
.business-manage-container > .field > .input-button-combo{
  width: calc(100% - 15px);
  padding: 5px;
  padding-left: 10px;
  /* font-size: 1.2em; */
  background-color: var(--BG_WHITE_COLOR);
  /* border-bottom: 1px solid black; */
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.business-manage-container > .field > .input-button-combo > input{
  width: calc( 100% - 100px );
  height: 100%;
  border: none;
  background-color: var(--BG_WHITE_COLOR);
  font-size: 1.2em;
}
.business-manage-container > .field > .input-button-combo > .input{
    width: calc( 100% - 100px );
    height: 100%;
    border: none;
    background-color: var(--BG_WHITE_COLOR);
    font-size: 1.2em;
  }
.business-manage-container > .field > .input-button-combo > button{
  width: 100px;
  font-size: 1.2em;
  padding: 0px;
}

.business-manage-container > .field > .search{
    width: 100%;
    margin-bottom: 10px;
}
.business-manage-container > .field > .search input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    border-radius: 5px;
}

.business-manage-container > .field > span > .red{
  color: red;
}
.business-manage-container > .field > span > .purple{
  color: purple;
}
.business-manage-container > .field > span > .premium{
  color: goldenrod;
}

.business-manage-container > .field > div.iframe{
  width: 100%;
  height: 300px;
  position: relative;
}
.business-manage-container > .field > div.iframe > img{
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  z-index: 100;
  font-size: 2em;
  color: var(--BTN_COLOR);
  text-shadow: 1px 1px 3px black;
  /* background-color: red; */
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.business-manage-container > .field > select{
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-size: 1.2em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  /* border-bottom: 1px solid black; */
}
.business-manage-container > button{
  width: 100%;
  font-size: 1.2em;
}













.business-manage-container.category > .input-button-combo{
    width: 100%;
}
.business-manage-container.category > .input-button-combo > img{
    height: 30px;
    width: 30px;
    background-color: var(--MAIN_COLOR);
    padding: 5px;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}
.business-manage-container.category > .input-button-combo > input{
  width: calc(100% - 60px);
  border: none;
  padding: 8px 0px;
  padding-left: 20px;
  font-size: 1.2em;
  border-radius: 5px 0px 0px 5px;
  box-shadow: none;
  background-color: var(--BG_WHITE_COLOR);
  height: 100%;
}


.category-widget{
  /* background-color: red; */
  height: auto;
  margin-bottom: 20px;
  width: 100%;
}
.category-widget > .header{
  margin-bottom: 10px;
  width: 100%;
}
.category-widget > .header > input{
  width: 100%;
  border: none;
  font-size: 1.4em;
  background-color: var(--BG_WHITE_COLOR);
  padding: 5px 0px 5px 10px;
  border-radius: 10px;
}

.category-widget > .header > img{
    height: 30px;
    width: 30px;
    /* background-color: red; */
    padding: 5px;
    cursor: pointer;
}
.category-widget > .content{
  /* background-color: blue; */
  width: calc(100% + 20px);
  height: auto;
  display: block;
}
.category-widget > .content > .widget{
  height: 200px;
  width: 170px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  float: left;
  background-color: var(--MAIN_COLOR);
  color: white;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}
.category-widget > .content > .widget > img{
  height: 170px;
  width: calc(170px - 2px);
  object-fit: cover;
  margin-bottom: 5px;
  border: thin solid var(--MAIN_COLOR);
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--BG_WHITE_COLOR);
}
.category-widget > .content > .widget > span{
  max-width: 100%;
  height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* background-color: red; */
  
  
  /* display: flex;
  align-items: center;
  justify-content: start; */
}
.category-widget > .content > div.empty{
  /* font-size: 1.5em; */
  height: 170px;
  width: calc(170px - 2px);
  text-align: center;
  background-color: rgba(128, 128, 128, 0.09);
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
}



















@media (max-width: 767px) { /* mobile */
  .category-widget > .content{
    width: calc(100%);
  }

  .category-widget > .content > .widget{
      height: 100px;
      width: calc( (100% / 4) - 5px );
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 2.5px;
      float: left;
      background-color: var(--MAIN_COLOR);
      color: white;
      border-radius: 5px;
      overflow: hidden;
  }
    
  .category-widget > .content > .widget > img{
      height: 100%;
      width: calc(100% - 2px);
      object-fit: cover;
      margin-bottom: 0px;
      border-radius: 5px 5px 0px 0px;
      border: thin solid var(--MAIN_COLOR);
      border-bottom: none;
      cursor: pointer;
  }
  .category-widget > .content > .widget > span{
    font-size: 0.7em;
    max-width: 85%;
  }

  .category-widget > .content > div.empty{
      /* font-size: 1.5em; */
    height: 100px;
    width: calc( (100% / 4) - 5px );
    text-align: center;
    background-color: rgba(128, 128, 128, 0.09);
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    font-size: 0.8em;
    border-radius: 5px;
    margin: 0px;
  }

}
.wizard-container{
    width: 100%;
    height: auto;
    /* min-height: 350px; */
    /* position: relative; */
    z-index: 0;
    /* background-color: brown; */
    position: relative;
}

.wizard-wand{
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
    margin-top: 10px;
}

.wizard-wand > .content{
    width: 100%;
    height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    /* background-color: red; */
    overflow: hidden;
    overflow-y: auto;
}

.wizard-wand > .nav{
    height: 60px;
    width: 100%;
    color: var(--BTN_COLOR);
    font-size: 1.6em;
    border-top: thin solid rgba(0, 0, 0, 0.1);
    /* background-color: white; */
    /* border-radius: 15px 15px 0px 0px; */
}
.wizard-wand > .nav > img{
    padding: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border-radius: 15px;
    background-color: var(--MAIN_COLOR_HALF);
}
.wizard-wand > .nav > img:hover{
    background-color: rgba(128, 128, 128, 0.2);
}
.wizard-wand > .nav > img.active{
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
    background-color: var(--MAIN_COLOR);
}




.items-header-nav{
  width: 100%;
  max-width: var(--MAX_WIDTH);
  height: auto;
  margin-bottom: 10px;
}
.items-header-nav > div{
  background-color: rgba(128, 128, 128, 0.5);
  height: 40px;
  padding: 0px 10px;
  color: white;
  cursor: pointer;
}
.items-header-nav > div.active{
  background-color: var(--MAIN_COLOR);
  height: 40px;
  padding: 0px 10px;
  color: white;
  cursor: pointer;
}
.items-header-nav > .grid img{
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.items-header-nav > .list img{
  height: 35px;
  width: 35px;
  margin-left: 10px;
}
.items-header-nav > .divider{
  width: 1px;
}


.items-header-search{
  width: calc(100% - 20px);
  max-width: var(--MAX_WIDTH);
  height: auto;
  background-color: var(--BG_WHITE_COLOR);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 10px;
}
.items-header-search input{
  width: calc(100% - 50px);
  height: 100%;
  border: none;
  background-color: var(--BG_WHITE_COLOR);
  border-radius: 10px 0px 0px 10px;
  font-size: 1.2em;
  padding: 5.5px 0px;
  padding-left: 10px;
  /* background-color: blue; */
}
.items-header-search img{
  height: 30px;
  padding: 5px 10px;
  /* background-color: red; */
}



.items-container{
  width: 100%;
  display: block;
  height: auto;
}
.items-container > .widget{
  height: 200px;
  width: calc(( 100% / 2 ) - 22px);
  max-width: 170px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  float: left;
  background-color: var(--MAIN_COLOR);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  text-decoration: none;
  position: relative;
}
.items-container > .widget > img{
  height: 170px;
  width: calc(100% - 2px);
  object-fit: cover;
  cursor: pointer;
  border: thin solid var(--MAIN_COLOR);
  border-radius: 10px 10px 0px 0px;
  background-color: var(--BG_WHITE_COLOR);
}
.items-container > .widget > span{
  max-width: 90%;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.items-container > .widget.add{
  justify-content: center;
  border-radius: 10px;
}
.items-container > .widget.add > img{
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  background-color: var(--MAIN_COLOR);
}
.items-container > .widget.add > span{
  font-size: 1.4em;
}
.item-wizard{
  width: 100%;
  height: calc(100vh - 150px);
  max-height: calc(100vh - 150px);
  /* background-color: red; */
  position: absolute;
  top: 0px;
  left: 0px;
}


.items-container-list{
  width: 100%;
}
.items-container-list > a{
  /* background-color: red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: black;
  padding: 10px 0px;
  border-bottom: thin solid rgba(128, 128, 128, 0.5);
  position: relative;
}
.items-container-list > a > img{
  height: 50px;
  width: 50px;
  object-fit: cover;
  background-color: var(--MAIN_COLOR);
  margin-right: 10px;
}
.items-container-list > a.add > img{
  border-radius: 50%;
}


.wizard-container .subtitle{
    font-size: 0.7em;
}
.wizard-container .field-single{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .wizard-container .field-single > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  .wizard-container .field-single > textarea{
    width: calc(100% - 15px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
  }
  
  
  .wizard-container .field-double{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .wizard-container .field-double input{
    width: calc(50% - 25px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  .wizard-container .field-double textarea{
    width: calc(50% - 25px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
  }
  .wizard-container button.submit{
    width: 100%;
    font-size: 1.4em;
  }
  
  .wizard-container .field > div.iframe{
    width: 100%;
    height: 300px;
    position: relative;
  }
  .wizard-container .field > div.iframe > i{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    z-index: 100;
    font-size: 2em;
    color: var(--BTN_COLOR);
    text-shadow: 1px 1px 3px black;
  }
  .wizard-container .field > .input-button-combo{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    /* font-size: 1.2em; */
    background-color: var(--BG_WHITE_COLOR);
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }
  .wizard-container .field > .input-button-combo > input{
    width: calc( 100% - 100px );
    height: 100%;
    border: none;
    background-color: var(--BG_WHITE_COLOR);
    font-size: 1.2em;
  }
  .wizard-container .field > .input-button-combo > button{
    width: 100px;
    font-size: 1.2em;
    padding: 0px;
  }



.wizard-widget.category{
  /* background-color: red; */
  min-height: calc(100vh - 200px);

}



/* .wizard-widget.images{
 
} */

.wizard-widget.images > .wizard-imgal{
  width: 100%;
  position: relative;
  /* max-height: 50vh; */

  /* add this line to view all of the image, not cropped */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.wizard-widget.images > .wizard-imgal .loader{
  width: calc(100% - 20px);
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}
.wizard-widget.images > .wizard-imgal .imgal-delete{
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 30px;
  width: 30px;
  background-color: var(--RED_PALETTE);
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.wizard-widget.images > .wizard-imgal .imgal-star{
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  
  background-color: var(--MAIN_COLOR);
  padding: 5px;
  /* padding: 5px; */
  border-radius: 50%;
  /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5); */
  opacity: 0.4;
  z-index: 10;
  cursor: pointer;
}
.wizard-widget.images > .wizard-imgal .imgal-star:hover{
  opacity: 0.6;
  /* box-shadow: 0px 0px 5px 0px rgb(0, 0, 0); */
}

.wizard-widget.images > .wizard-imgal .imgal-star.active{
  opacity: 1;
  transform: scale(1.2);
  /* background-color: red; */
}
.wizard-widget.images > .wizard-imgal .imgal-star.active:hover{
  opacity: 1;
  box-shadow: 0px 0px 5px 0px rgb(0, 0, 0);
  background-color: rgba(184, 184, 184, 0.1);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
}

.wizard-widget.images > .wizard-imgal .image-gallery-slides{
  max-height: 50vh;
}
.wizard-widget.images > .wizard-imgal .image-gallery-slides img{
  max-height: 50vh;
  object-fit: cover;
}
.wizard-widget.images > .wizard-imgal .image-gallery-slide-wrapper > button{
   box-shadow: none;
}
.wizard-widget.images > .wizard-imgal .image-gallery-slide-wrapper > button:hover{
  color: var(--MAIN_COLOR);
  background-color: rgba(169, 169, 169, 0.1);
}



/* .wizard-widget.price{
  
} */
.price-create > button{
  border-radius: 5px 5px 0px 0px;
}
.price-create-popup{
  background-color: white;
  padding: 10px;
  border: thin solid rgba(128, 128, 128, 0.2);
  border-top: none;
}
/* .price-widget-con{

} */

.price-widget{
  width: calc(100% - 20px);
  /* background-color: white; */
  padding: 5px 10px;
  margin-top: 10px;
  border-bottom: thin solid rgba(128, 128, 128, 0.2);
}
.price-widget > .header{
  /* background-color: blue; */
  margin-bottom: 5px;
}
.price-widget > .header input{
  border: none;
  background-color: transparent;
  padding: 3px 5px;
  font-size: 1.1em;
  border-radius: 5px;
  width: calc( 100% - 40px );
  max-width: calc( 100% - 40px );
  /* font-weight: bold; */
}
.price-widget > .header .inputHolder{
  font-size: 1.1em;
  border-radius: 5px;
  width: calc( 100% - 40px );
  max-width: calc( 100% - 40px );
}
.price-widget > .header img{
  height: 20px;
  /* background-color: red; */
  padding: 4.5px;
}

.price-widget > .editable textarea{
  resize: none;
  border: none;
  border-radius: 5px;
  width: calc(100% - 10px);
  font-size: 0.9em;
  font-family: Poppins, sans-serif;
  padding: 5px;
  margin-bottom: 5px;
  background-color: transparent;
}
.price-widget > .editable input{
  border: none;
  background-color: transparent;
  padding: 3px 5px;
  font-size: 1.1em;
  border-radius: 5px;
  width: calc( 100% - 40px );
  max-width: calc( 100% - 40px );
}


.price-widget > .editable img{
  height: 20px;
  /* background-color: red; */
  padding: 4.5px;
}



.price-widget.onEdit input,
.price-widget.onEdit textarea{
  background-color: var(--BG_WHITE_COLOR);
}





.availability-create-popup{
  background-color: white;
  padding: 10px;
  border: thin solid rgba(128, 128, 128, 0.2);
  border-top: none;
  height: auto;
}

.availability-widget{
  width: calc(100% - 20px);
  padding: 10px;
  /* border: thin solid rgba(128, 128, 128, 0.2); */
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

/* .availability-widget input{
  width: calc(100% - 15px);
  padding: 5px;
  padding-left: 10px;
  font-size: 1.2em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  border-radius: 5px;
} */
.availability-widget select{
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-size: 1em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  /* border-bottom: 1px solid black; */
  margin-bottom: 5px;
}
.availability-widget > .header img{
  height: 30px;
  width: 30px;
  padding: 5px;
}
.availability-widget > .editable > div{
  width: 100%;
}

.availability-widget > .editable > .double > .field input{
  width: calc(100% - 20px);
}
.availability-widget > .editable > label{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0px;
}
.availability-widget > .editable input[type="date"],
.availability-widget > .editable input[type="text"],
.availability-widget > .editable input[type="time"]{
  width: calc(100% - 15px);
  padding: 5px;
  padding-left: 10px;
  font-size: 1.2em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
}
.availability-widget > .editable > label > span{
  /* white-space: nowrap; */
  width: auto;
  background-color: red;
}








@media (max-width: 767px) { /* mobile */



    .wizard-container{
        width: 100%;
        height: auto;
        /* min-height: 350px; */
        /* position: relative; */
        z-index: 0;
        /* background-color: brown; */
        position: relative;
    }
    
    .wizard-wand{
        width: calc(100% - 20px);
        max-width: var(--MAX_WIDTH);
        margin-top: 10px;
    }
    
    .wizard-wand > .content{
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: unset;
        /* background-color: green; */
        overflow: hidden;
        overflow-y: unset;
        margin-bottom: 10px;
    }
    
    .wizard-wand > .nav{
        height: auto;
        width: 60px;
        font-size: 1.6em;
        border-top: none;
        position: fixed;
        right: 0px;
        bottom: 12vh;
        background-color: var(--MAIN_COLOR_HALF);
        -webkit-backdrop-filter: blur(6px);
                backdrop-filter: blur(6px);
        border-radius: 15px 0px 0px 15px;
        padding: 10px 5px 5px 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        z-index: 99999999999999999999999999999;
    }
    .wizard-wand > .nav > img{
        padding: 10px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        background-color: unset;
    }
    .wizard-wand > .nav > img:last-child{
        border-top: thin solid rgba(0, 0, 0, 0.1);
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-top: 10px;
        border-radius: 0px;
    }
    
    
    .wizard-wand  > .sidebarOpener{
        position: fixed;
        right: 0px;
        bottom: 15vh;
        bottom: 150px;
        height: 150px;
        width: 10px;
        background-color: var(--MAIN_COLOR_HALF);
        border-radius: 15px 0px 0px 15px;
    }
    .wizard-wand  > .sidebarOpener > .h{
        height: 80%;
        width: 1px;
        /* background-color: rgba(136, 136, 136, 0.2); */
        background-color: var(--MAIN_COLOR_HALF);
        box-shadow: 1px 0px 5px rgba(255, 255, 255, 0.5);
        position: relative;
        left: -2px;
    }

    .wizard-widget.images > .wizard-imgal .image-gallery-slides{
      max-height: calc(100vw - 20px);
    }
    .wizard-widget.images > .wizard-imgal .image-gallery-slides img{
      max-height: calc(100vw - 20px);
      object-fit: cover;
    }
    .wizard-widget.images > .wizard-imgal .image-gallery-slide-wrapper > button{
      display: none;
    }


}
.nearby-container{
    width: 100vw;
    height: calc(100vh - 78px);
    position: relative;
}

.nearby-container > .iframe{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
.nearby-container > .iframe > img{
    display: none;
}
.nearby-container > .search{
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
    padding: 5px 10px;
    position: absolute;
    top: 20px;
    z-index: 5;
    
}

.nearby-container > .search > div[data-reach-combobox]{
    /* background-color: red; */
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 5px 0px black;
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 5px;
    /* overflow: hidden; */
}
.nearby-container > .search input{
    width: calc(100% - 100px);
    margin-left: 10px;
    border: none;
    font-size: 1.4em;
    background-color: var(--BG_WHITE_COLOR);
    /* background-color: red; */
}
.nearby-container > .search img{
    height: 30px;
    width: 30px;
    object-fit: cover;
    padding: 5px;

}
.nearby-container > .search ul{
    width: calc(100% - 20px);
    position: absolute;
    top: 50px;
    left: 0px;
    background-color: var(--BG_WHITE_COLOR);
    background-color: rgb(245, 245, 245);
    padding: 10px;
}
.nearby-container > .search ul li{
    border-bottom: thin solid rgba(128, 128, 128, 0.5);
    margin-bottom: 10px;
    color: #d5c499;
}

.nearby-container > button{
    position: absolute;
    top: 100px;
    z-index: 5;
}



.nearby-mapcard{
    height: auto;
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
    padding: 10px;
    /* background-color: var(--MAIN_COLOR); */
    background-color: #d5c499;
    /* background-color: var(--BG_WHITE_COLOR); */
    /* background-color: white; */
    z-index: 5;
    position: absolute;
    bottom: 10px;
    border-radius: 15px;
}
.nearby-mapcard .close{
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    width: 20px;
    padding: 5px;
    background-color: inherit;
    border-radius: 0px 0px 0px 10px;
    z-index: 5;
}
.nearby-mapcard > .store{
    height: 150px;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}
.nearby-mapcard > .store .banner{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.nearby-mapcard > .store .logo{
    position: absolute;
    bottom: 5px;
    left: 5px;
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
}
.nearby-mapcard .featured{
    width: 100%;
}
.nearby-mapcard .featured > a {
    width: calc( (100% / 5) - 10px );
}
.nearby-mapcard .featured > a > img{
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}
.nearby-mapcard .about{
    width: 100%;
    height: auto;
    /* background-color: red; */
    font-size: 0.8em;
    color: white;
}
.nearby-mapcard .about > .storename{
    font-size: 1.4em;
    /* font-weight: bold; */
}
.nearby-mapcard .about > .info{
    /* margin-left: 10px; */
    color: white;
}
.nearby-mapcard .about img{
    height: 20px;
    width: 20px;
    margin-right: 5px;
    margin-left: 15px;
}
.nearby-mapcard .about > .info > p{
    margin: 0px 10px;
    font-size: 2em;
}




@media (max-width: 767px) { /* mobile */
    .nearby-mapcard > .store{
        height: 100px;
        width: 100%;
    }
    .nearby-mapcard > .store > .banner{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    .nearby-mapcard > .store .logo{
        position: absolute;
        bottom: 5px;
        left: 5px;
        height: 70px;
        width: 70px;
        object-fit: cover;
        border-radius: 10px;
    }
    .nearby-mapcard .featured > a {
        width: calc( (100% / 3) - 5px );
    }
    .nearby-mapcard .featured > a > img{
        width: 100%;
        height: 70px;
        object-fit: cover;
        border-radius: 10px;
    }
    .nearby-mapcard .about{
        width: 100%;
        height: auto;
        font-size: 0.8em;
    }
    .nearby-mapcard .about img{
        height: 20px;
        width: 20px;
        margin-right: 5px;
        margin-left: 10px;
    }
    .nearby-mapcard .about > .info > p{
        margin: 0px 10px;
        font-size: 2em;
    }
    .nearby-mapcard .about > .info span{
        margin-bottom: 10px;
    }
    

}

.store-wrapper{
    width: 100vw - 20px;
    height: auto;
    /* background-color: blue; */
    overflow: hidden;
}

.store-container{
    width: calc(100% - 20px);
    padding: 10px 0px;
    max-width: var(--MAX_WIDTH);
    height: auto;
    /* background-color: blue; */
}

.store-header{
    width: 100%;
    height: auto;
}
.store-header > .logo-banner{
    position: relative;
    height: 40vh;
    width: 100%;
}
.store-header > .logo-banner > .banner{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.store-header > .logo-banner > .logo{
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 5px;
    left: 5px;
    background-color: var(--BG_WHITE_COLOR);
    padding: 5px;
    border-radius: 10px;
    object-fit: cover;
}
.store-header > .logo-banner > .dineSafePass{
    position: absolute;
    bottom: 10px;
    right: 10px;
    max-height: 100px;
}
.store-header > .store-name{
    font-size: 1.4em;
    padding: 10px 10px 0px 10px;
}

.store-header.item-mode > .logo-banner{
    max-height: 100px;
    /* transition: max-height 0.5s ease-out; */
    /* transform: translateY(120); */
}
.store-header.item-mode > .logo-banner > .logo{
    max-height: 80px;
    max-width: 80px;
    /* transition: max-height 1s ease-out;  */

}
.store-header.item-mode > .logo-banner > .dineSafePass{
    max-height: 50px;
    /* transition: max-height 1.5s ease-out; */
}











.store-optional-content{
    width: 100%;
    height: auto;
    /* background-color: red; */
}
.store-optional-content > .store-name{
    font-size: 1.4em;
    font-weight: bold;
}
.store-optional-content > .details{
    font-size: 0.8em;
}
.store-optional-content > .details a{
    color: black;
    text-decoration: none;
}
.store-optional-content > .details img{
    height: 20px;
    margin: 0px 5px 5px 15px;

}

/* .store-optional-content > .details.desktop{
    display: block;
    height: auto;
}
.store-optional-content > .details.desktop *{
    float: left;
} */

.store-optional-content > .iframe{
    width: 100%;
    height: 300px;
    /* background-color: red; */
    /* position: relative; */
    /* margin-top: 10px; */
}
.store-optional-content > .iframe > img{
    position: absolute;
    top: 0px;
    right: 0px;
    /* z-index: 99999; */
    /* transform: scale(5); */
    display: none;
}
.store-optional-content > .title{
    font-size: 1.2em;
    margin-top: 10px;
}
.store-optional-content > .blank{
    width: 100%;
    height: 150px;
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 5px;
}

.store-category-nav{
    width: 100%;
    height: 40px;
    /* background-color: red; */
    margin-top: 10px;
    position: relative;
    background-color: white;
}
.store-category-nav > img{
    height: 30px;
    width: 30px;
    object-fit: cover;
    margin-right: 10px;
}
.store-category-nav > .cat-container{
    overflow: hidden;
    overflow-x: auto;
    height: 100%;
}

.store-category-nav > .cat-container::-webkit-scrollbar {
  width: 3px;
  height: 0px;
}
/* Track */
.store-category-nav > .cat-container::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.07);  */
  background: white; 
  border-radius: 5px;
}
/* Handle */
.store-category-nav > .cat-container::-webkit-scrollbar-thumb {
  /* background: #888;  */
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
/* Handle on hover */
.store-category-nav > .cat-container::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
  background: var(--BTN_COLOR); 
}


.store-category-nav > .cat-container > span{
    white-space: nowrap;
    /* background-color: blue; */
    margin: 0px 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: thin solid transparent;
    cursor: pointer;
    color: grey;
}
.store-category-nav > .cat-container > span.active{
    border-bottom: thin solid var(--MAIN_COLOR);
    color: black;
}

.store-category-nav > .cat-popup{
    width: auto;
    height: auto;
    max-height: 50vh;
    padding: 10px 20px 10px 10px;
    background-color: var(--BG_WHITE_COLOR);
    position: absolute;
    top: 40px;
    z-index: 5;
    overflow: hidden;
    overflow-y: auto;
}
.store-category-nav > .cat-popup > .widget{
    margin-bottom: 5px;
    cursor: pointer;
    color: grey;
    width: calc(100vw - 40px);
    /* max-width: calc(100vw - 50px); */
    max-width: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.store-category-nav > .cat-popup > .widget.active{
    border-bottom: thin solid var(--MAIN_COLOR);
    color: black;
}
.store-category-nav > .cat-popup > .widget > p{
    font-size: 0.9em;
}
.store-category-nav > .cat-popup > .widget > span{
    width: 90%;
    max-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.store-item-container{
    width: 100%;
    /* background-color: red; */
}
.store-item-container > .title{
    font-weight: bold;
    width: 100%;
    margin-top: 20px;
}

.store-cat-con{
    width: 100%;
}
.store-item-widget{
    height: auto;
    width: calc( (100% / 2) - 25px );
    padding: 10px;
    background-color: var(--BG_WHITE_COLOR);
    margin-top: 10px;
    float: left;
    color: black;
    text-decoration: none;
    position: relative;
}
.store-item-widget:nth-child(odd){
    margin-right: 10px;
}


.store-item-widget > img{
    height: 100px;
    width: 100px;
    padding: 5px;
    background-color: inherit;
    border-radius: 10px;
    object-fit: cover;
}
.store-item-widget > .details{
    width: calc(100% - 120px);
    max-width: calc(100% - 120px);
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
}

.store-item-widget > .details span{
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    float: left;
}
.store-item-widget > .details .name{
    font-weight: bold;
    font-size: 1.1em;

}
.store-item-widget > img.alert{
    height: 15px;
    width: 15px;
    background-color: var(--RED_PALETTE);
    border-radius: 50%;
    margin-left: 10px;
    position: absolute;
    right: 5px;
    top: 5px;
}









.store-item-content{
    width: 100%;
    /* set this if  you want persistent 100% width */
}
.store-item-content > .imgal{
    height: 350px;
    width: calc(100%);
    min-width: calc(100% - 20px);
    overflow: hidden;
    margin-top: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}
.store-item-content > .image-gallery{
    height: 100%;
    /* background-color: black; */
}

.store-item-content .image-gallery-slides{
    max-height: calc(100vw - 20px);
    /* background-color: red; */
}
.store-item-content .image-gallery-slide img{
    max-height: 350px;
    height: 350px;
    max-width: var(--MAX_WIDTH);
    object-fit: cover;
}
.store-item-content > .imgal .image-gallery-slide-wrapper > button.image-gallery-right-nav{
    display: none;
}
.store-item-content > .imgal .image-gallery-slide-wrapper > button.image-gallery-left-nav{
    display: none;
}

.store-item-content > .title{
    font-weight: bold;
    font-size: 1.2em;
    margin: 5px 0px;
    width: 100%;
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
}

.store-item-content > .field{
    margin-bottom: 10px;
    width: calc(100%);
}
.store-item-content > .field > span{
    font-size: 0.9em;
    background-color: var(--BG_WHITE_COLOR);
    padding: 5px 10px;
    border-radius: 5px;
    color: black;
}
.store-item-content > .field > .description{
    font-size: 0.9em;
    background-color: var(--BG_WHITE_COLOR);
    padding: 5px 10px;
    border-radius: 5px;
    color: black;
}
.store-item-content > .field > .alert{
    background-color: var(--RED_PALETTE);
    padding: 3px 10px;
    color: white;
    position: relative;
    padding-right: 20px;
    max-width: calc(100% - 40px);
}
.store-item-content > .field > .alert img{
    height: 20px;
    width: 20px;
    position: absolute;
    top: -10px;
    right: -15px;
    padding: 2px;
    background-color: var(--RED_PALETTE);
    border-radius: 50%;
    border: 3px solid white;
}

.store-item-content > .field > .tags > a{
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 5px;
    padding: 3px 10px;
    margin: 3px;
    font-size: 0.9em;
    text-decoration: none;
    color: black;
}
.store-item-content > .field > .tags > a > b{
    font-weight: normal;
    font-size: 0.8em;
}
.store-item-content > .field > .availability > span{
    font-size: 0.9em;
    padding: 0px 10px;
    border-radius: 5px;
    color: black;
    width: 50%;
    margin-bottom: 5px;
}
.store-item-content > .field > .availability > span > p{
    background-color: var(--BG_WHITE_COLOR);
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 0.9em;
}

.store-item-content > .field > .price{
    background-color: var(--BG_WHITE_COLOR);
    background-color: var(--MAIN_COLOR);
    width: calc(100% - 20px);
    padding: 5px 10px;
    margin-bottom: 5px;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    position: relative;
}
.store-item-content > .field > .price > span{
    font-size: 0.9em;
    padding: 0px;
    border-radius: 5px;
    /* color: black; */
    width: 100%;
    /* width: 100%; */
    font-weight: bold;
}
.store-item-content > .field > .price > span > p{
    font-size: 0.9em;
    font-weight: normal;
}
.store-item-content > .field > .price > .note{
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.store-item-content > .field > .price > .cart{
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: white;
    padding: 0px 5px;
    border-radius: 3px;
}
.store-item-content > .field > .price > .cart p{
    font-size: 0.7em;
    margin-left: 5px;
    color: var(--MAIN_COLOR);
}
.store-item-content > .field > .price > .cart .basket{
    color: var(--MAIN_COLOR);
}

.store-item-content > .field > .blank{
    width: 100%;
    height: 350px;
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 5px;
}





.sticky {
    position: fixed;
    top: 0;
    margin-top: 0px;
    width: 100%;
    max-width: var(--MAX_WIDTH);
    padding-bottom: 10px;
    z-index: 5;
}

.sticky + .store-item-container {
    padding-top: 50px;
}

.subtitle{
    font-size: 0.8em;
}
.error{
    color: var(--RED_PALETTE);
}






.store-orders-container{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);

}
.store-basket{
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}
.store-basket > svg{
    padding: 10px;
    height: 30px;
    width: 30px;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 50%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}
.store-orders-container .close{
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    width: 20px;
    padding: 10px;
}
.store-orders-container .store-orders{
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
    height: auto;
    max-height: 90vh;
    /* min-height: 350px; */
    padding: 10px;
    position: relative;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    overflow: hidden;
    overflow-y: auto;
}
.store-orders-container .store-orders > .empty{
    width: 100%;
    padding: 20px 0px;
    /* background-color: rgb(200, 200, 200); */
    color: grey;
    border-radius: 10px;
    border: 2px dashed grey;
}
.store-orders-container .store-orders > .title{
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
}

.store-orders-container .store-orders > .widget{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}
.store-orders-container .store-orders > .widget > .header{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    /* border-radius: 10px; */
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 5px;
}
.store-orders-container .store-orders > .widget > .option{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    /* border-radius: 10px; */
    margin-bottom: 5px;
    color: grey;
    position: relative;
    overflow: hidden;
}
.store-orders-container .store-orders > .widget > .option:last-child{
    border-radius: 0px 0px 10px 10px;
}
.store-orders-container .store-orders > .widget > .option > .opwid{
    width: 100%;
    padding: 2.5px 0px;
}
.store-orders-container .store-orders > .widget > .option > .opwid.subtotal{
    /* font-size: 0.8em; */
    border-top: thin solid rgba(130, 130, 130, 0.2);
    color: black;
}
.store-orders-container .store-orders > .widget > .option > .opwid.subtotal > p{
    margin-right: 17px;
}
.store-orders-container .store-orders > .widget > .option > .opwid > .action > svg{
    height: 12px;
    width: 12px;
    margin-left: 5px;
}
.store-orders-container .store-orders > .widget > .option > .opwid > span{
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/* .store-orders-container .store-orders > .widget > .option > .delete{
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 15px;
    width: 15px;
    padding: 5px;
} */









@media (max-width: 767px) { /* mobile */
    .store-header > .logo-banner{
        position: relative;
        height: 30vh;
        width: 100%;
    }
    .store-header > .logo-banner > .banner{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .store-header > .logo-banner > .logo{
        width: 70px;
        height: 70px;
        position: absolute;
        bottom: 5px;
        left: 5px;
        background-color: var(--BG_WHITE_COLOR);
        padding: 5px;
        border-radius: 10px;
    }
    .store-header > .logo-banner > .dineSafePass{
        position: absolute;
        bottom: 10px;
        right: 10px;
        max-height: 70px;
    }
    .store-category-nav > .cat-popup > .widget{
        max-width: calc(100vw - 50px);
    }
    .sticky {
        max-width: calc(100vw - 20px);
    }

    .store-item-widget{
        width: calc(100% - 20px);
    }
    .store-item-widget:nth-child(odd){
        margin-right: 0px;
    }

    /* .store-item-content .image-gallery-slides img{
        max-height: calc(100vw - 20px);
        object-fit: cover;
    } */
    .store-item-content .image-gallery-slide img{
        /* max-height: calc(var(--MAX_WIDTH) - 20px); */
        max-height: 30vh;
        height: 30vh;
        object-fit: cover;
        /* background-color: red; */
    }
    .store-item-content > .imgal{
        height: auto;
    }
    .store-item-content > .field > .availability > span{
        font-size: 0.9em;
        padding: 0px;
        border-radius: 5px;
        color: black;
        width: 100%;
        margin-bottom: 5px;
    }

}
.chikkas-wrapper{
    width: calc(100vw);
    height: auto;
}

.chikkas-container{
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 78px);
    max-height: calc(100vh - 78px);

    /* background-color: blue; */
    position: relative;
}


.chikka-list{
    width: 400px;
    min-width: 400px;
    height: calc(100vh - 98px);
    max-height: calc(100vh - 98px);
    background-color: white;
    border-right: thin solid rgba(128, 128, 128, 0.5);
    z-index: 2;
}



.chikka-list > .header{
    width: calc(100% - 20px);
    /* border-bottom: thin solid rgba(128, 128, 128); */
    padding: 10px;
    cursor: pointer;
    /* background-color: red; */
}
.chikka-list > .header > img{
    height: 30px;
    width: 30px;
    object-fit: cover;
    margin-right: 5px;
}
.chikka-list > .header > span{
    font-size: 1.2em;
}

.chikka-list > .search{
    width: calc(100% - 20px);
    height: auto;
    padding: 0px 10px;
    /* border-bottom: thin solid rgba(128, 128, 128); */
    padding-bottom: 10px;
    margin-top: 10px;
}
.chikka-list > .search > .search-con{
    position: relative;
}
.chikka-list > .search > .search-con > input{
    width: calc(100% - 40px);
    padding: 10px 40px 10px 10px;
    font-size: 1.1em;
    border: none;
    border-radius: 10px;
    background-color: var(--BG_WHITE_COLOR);
}
.chikka-list > .search > .search-con > svg{
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    cursor: pointer;
}


.chikka-list > .widget-container{
    
    width: calc(400px);
    height: auto;
    max-height: calc(100% - 40px);
    overflow: hidden;
    position: relative;
}
.chikka-list > .widget-container:hover{
    overflow-y: overlay;
}

.chikka-list > .widget-container::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}
.chikka-list > .widget-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.07); 
    border-radius: 5px;
}
.chikka-list > .widget-container::-webkit-scrollbar-thumb {
    background: var(--MAIN_COLOR_HALF); 
    border-radius: 5px;
    cursor: grab;
}
.chikka-list > .widget-container::-webkit-scrollbar-thumb:hover {
    background: var(--MAIN_COLOR); 
}


.chikka-list > .widget-container > .list-widget{
    width: calc(100% - 40px);
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
}
.chikka-list > .widget-container > .list-widget.active{
    background-color: var(--BG_WHITE_COLOR);
}
.chikka-list > .widget-container > .list-widget:hover{
    background-color: var(--BG_WHITE_COLOR);
}
.chikka-list > .widget-container > .list-widget.unread{
    font-weight: 500;
}
.chikka-list > .widget-container > .list-widget:last-child{
    margin-bottom: 0px;
}

.chikka-list > .widget-container > .list-widget > .chat-logo{
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 50%;
}
.chikka-list > .widget-container > .list-widget > .business-logo{
    position: absolute;
    bottom: 10px;
    height: 20px;
    width: 20px;
    object-fit: cover;
    border-radius: 50%;
}
.chikka-list > .widget-container > .list-widget > .details{
    height: 100%;
    width: calc(100% - 100px);
    /* background-color: red; */
    margin-left: 10px;
}
.chikka-list > .widget-container > .list-widget > .details > .title{
    max-width: calc(100%);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.chikka-list > .widget-container > .list-widget > .details > .subtitle{
    color: grey;
    font-weight: normal;
    width: 100%;
    /* background-color: red; */
}
.chikka-list > .widget-container > .list-widget > .details > .subtitle > .message{
    width: 80%;
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.chikka-list > .widget-container > .list-widget > .details > .subtitle > .date{
    width: calc(20% - 10px);
    /* background-color: blue; */
}
.chikka-list > .widget-container > .list-widget > .details > .user-icons{
    width: 100%;
    height: 20px;
    /* background-color: red; */
}
.chikka-list > .widget-container > .list-widget > .details > .user-icons > span{
    /* font-size: ; */
    color: grey;
}
.chikka-list > .widget-container > .list-widget > .details > .user-icons > img{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
}
.chikka-list > .widget-container > .list-widget > .indicators{
    width: 30px;
    height: 100%;
    /* background-color: red; */
}
.chikka-list > .widget-container > .list-widget > .indicators > .image-indicator{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    object-fit: cover;
}
.chikka-list > .widget-container > .list-widget > .indicators > .icon-indicator{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    object-fit: cover;
    color: grey;
}
.chikka-list > .widget-container > .list-widget > .indicators > .dot-indicator{
    padding: 10px;
    border-radius: 50%;
    background-color: black;
}


.chikka-list > .widget-container > .list-widget > .indicators > .dot-indicator.error{
    background-color: var(--RED_PALETTE);
}
.chikka-list > .widget-container > .list-widget > .indicators > .dot-indicator.active{
    background-color: var(--GREEN_PALETTE);
}
.chikka-list > .widget-container > .list-widget > .indicators > .dot-indicator.unread{
    background-color: var(--BLUE_PALETTE);
}







.chikka-message{
    width: calc(100% - 400px);
    height: calc(100% - 20px);
    background-color: white;

    
    z-index: 1;
}
.chikka-message > .header{
    width: calc(100% - 20px);
    height: 50px;
    padding: 10px;
    /* background-color: red; */
    /* border-bottom: thin solid rgba(128, 128, 128, 0.2); */
    box-shadow: 0px 1px 3px 0px rgba(150, 150, 150, 0.2);
    position: relative;
}
.chikka-message > .header > .actions{
    width: 120px;
    height: 100%;
    /* background-color: blue; */
}
.chikka-message > .header > .actions > .sbg{
    min-height: 50px;
    min-width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.chikka-message > .header > .actions > .sbg:hover{
    background-color: var(--BG_WHITE_COLOR);
}
.chikka-message > .header > .actions > .sbg > svg{
    color: var(--MAIN_COLOR);
    height: 20px;
    width: 20px;
    padding: 10px;
}
.chikka-message > .header > .profile{
    width: calc(100% - 130px);
    height: 100%;
}
.chikka-message > .header > .profile > img{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}
.chikka-message > .header > .profile > .title{
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.chikka-message > .header > .verify{
    position: absolute;
    top: 70px;
    left: 0px;
    height: auto;
    width: calc(100% - 20px);
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px;
    -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
    box-shadow: 0px 1px 3px 0px rgba(150, 150, 150, 0.2);
    padding-bottom: 20px;
}
.chikka-message > .header > .verify > .title{
    width: 100%;
    text-align: center;
}
.chikka-message > .header > .verify .action{
    margin-top: 10px;
}
.chikka-message > .header > .verify .action button{
    padding: 10px 30px;
}
.chikka-message > .header > .verify .more{
    font-size: 0.7em;
    position: absolute;
    bottom: 0px;
    right: 10px;
}

.chikka-message > .message-sender{
    height: auto;
    min-height: 50px;
    width: calc(100% - 20px);
    /* background-color: brown; */
    position: relative;
    padding: 0px 10px;
}
.chikka-message > .message-sender > .emoji-picker-react{
    position: absolute;
    bottom: 50px;
    left: 0px;
    width: 100%;
    height: calc(76.6vh);
    box-shadow: none;
}
.chikka-message > .message-sender > .emoji-picker-react button{
    box-shadow: none;
}
.chikka-message > .message-sender > .form{
    width: calc(100% - 40px);
    height: auto;
    position: relative;
}
.chikka-message > .message-sender > .form > .sender{
    width: calc(100% - 20px);
    height: 25px;
    background-color: var(--BG_WHITE_COLOR);
    font-size: 1 em;
    resize: none;
    border: none;
    border-radius: 10px;
    padding: 5px 40px 5px 10px;
    font-size: 1em;
    font-family: slim;
}
.chikka-message > .message-sender > .form > .sender::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
.chikka-message > .message-sender > .form > svg{
    position: absolute;
    right: 10px;
    
}
.chikka-message > .message-sender > svg{
    width: 30px;
    height: 30px;
    color: var(--MAIN_COLOR);
}

.chikka-message > .message-container{
    height: calc(100% - 120px);
    max-height: calc(100% - 120px);
    width: calc(100% - 20px);
    padding: 0px 10px;
    /* background-color: blue; */

    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}

.chikka-message > .message-container > .message-time{
    width: 100%;
    text-align: center;
    color: grey;
    /* background-color: red; */
}
.chikka-message > .message-container > .message-widget{
    width: calc(80%);
    height: auto;
    padding: 5px 0px;
    /* background-color: red; */
    font-size: 0.9em;
}
.chikka-message > .message-container > .message-widget.user{
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
}
.chikka-message > .message-container > .message-widget.owner{
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.chikka-message > .message-container > .message-widget > img{
    height: 20px;
    width: 20px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 8px;
}
.chikka-message > .message-container > .message-widget > span{
    background-color: var(--BG_WHITE_COLOR);
    padding: 5px 10px;
    border-radius: 10px;
}
.chikka-message > .message-container > .message-widget.owner > span{
    background-color: var(--MAIN_COLOR);
    color: white;
}
.chikka-message > .message-container > .message-widget > .actions{
    width: 50px;
    min-width: 50px;
}
.chikka-message > .message-container > .message-widget > .actions > svg{
    height: 20px;
    width: 20px;
    margin-left: 0px;
    padding: 5px;
    color: rgb(199, 199, 199);
    cursor: pointer;
    display: none;
}
.chikka-message > .message-container > .message-widget:hover > .actions > svg{
    display: block;
}







.chikka-options{
    width: 350px;
    height: calc(100vh - 98px);
    max-height: calc(100vh - 98px);
    padding: 10px;
    background-color: white;
    position: absolute;
    right: 0px;
    top: 0px;
    overflow: hidden;
    overflow-y: auto;
    border-left: thin solid rgba(128, 128, 128, 0.5);
    z-index: 3;
}
.chikka-options::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.chikka-options > .close{
    position: absolute;
    top: 0px;
    right: 0px;
    height: 30px;
    width: 30px;
    padding: 10px;
}
/* .chikka-options > .item-details{
    width: 100%;
    height: auto;
}
.chikka-options > .item-details > .item-img{
    width: calc(100vw - 100px);
    height: calc(100vw - 100px);
    max-width: calc(350px - 100px);
    max-height: calc(350px - 100px);
    border-radius: 50%;
    object-fit: cover;
    background-color: var(--BG_WHITE_COLOR);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}
.chikka-options > .item-details > .title{
    font-size: 1.2em;
} */


.chikka-options > .item-details{
    width: calc(100%);
    max-width: var(--MAX_WIDTH);
    height: auto;
    max-height: 90vh;
    /* min-height: 350px; */
    /* padding: 10px; */
    position: relative;
    background-color: white;
    /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
    /* border-radius: 10px; */
    /* overflow: hidden; */
    /* overflow-y: auto; */
    margin-top: 50px;
}
.chikka-options > .item-details > .empty{
    width: 100%;
    padding: 20px 0px;
    /* background-color: rgb(200, 200, 200); */
    color: grey;
    border-radius: 10px;
    border: 2px dashed grey;
}
.chikka-options > .item-details > .title{
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
}

.chikka-options > .item-details > .widget{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}
.chikka-options > .item-details > .widget > .header{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    /* border-radius: 10px; */
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 5px;
    cursor: pointer;
}
.chikka-options > .item-details > .widget > .option{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    /* border-radius: 10px; */
    margin-bottom: 5px;
    color: grey;
    position: relative;
    overflow: hidden;
}
.chikka-options > .item-details > .widget > .option:last-child{
    border-radius: 0px 0px 10px 10px;
}
.chikka-options > .item-details > .widget > .option > .opwid{
    width: 100%;
    padding: 2.5px 0px;
}
.chikka-options > .item-details > .widget > .option > .opwid.subtotal{
    /* font-size: 0.8em; */
    border-top: thin solid rgba(130, 130, 130, 0.2);
    color: black;
}
.chikka-options > .item-details > .widget > .option > .opwid.subtotal > p{
    margin-right: 17px;
}
.chikka-options > .item-details > .widget > .option > .opwid > .action > svg{
    height: 12px;
    width: 12px;
    margin-left: 5px;
    cursor: pointer;
}
.chikka-options > .item-details > .widget > .option > .opwid > span{
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.chikka-options > .item-details > .option{
    width: calc(100% - 20px);
    padding: 10px;
    height: auto;
    background-color: var(--BG_WHITE_COLOR);
    margin-bottom: 15px;
    border-radius: 10px;
}
.chikka-options > .item-details > .option > .title{
    margin-bottom: 10px;
}
.chikka-options > .item-details > .option > .type{
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    /* margin: 10px 0px; */
    margin-bottom: 10px;
}
.chikka-options > .item-details > .option > .type > span{
    
    background-color: grey;
    color: rgb(200, 200, 200);
    width: calc(50% - 1px);
    text-align: center;
    padding: 3px 0px;
}
.chikka-options > .item-details > .option > .type > span.active{
    background-color: var(--MAIN_COLOR);
    color: white;
}
.chikka-options > .item-details > .option > input{
    width: calc(100% - 10px);
    font-size: 1.1em;
    border: none;
    border-radius: 5px;
    padding: 3px 0px 3px 10px;
}
.chikka-options > .item-details > .option > input:disabled{
    background-color: white;
}
.chikka-options > .item-details > .option > button{
    width: 100%;
    margin-top: 10px;
    box-shadow: none;
    font-size: 1.1em;
}
.chikka-options > .item-details > .option > .subtitle{
    margin-bottom: 5px;
}
.chikka-options > .item-details > .option > .subtitle.discount{
    color: var(--GREEN_PALETTE);
}
.chikka-options > .item-details > .note{
    /* height: 30px; */
    width: calc(100% - 20px);
    padding: 0px 10px;
    font-size: 0.7em;
    margin-bottom: 10px;
    /* background-color: red; */
}
.chikka-options > .item-details > button{
    box-shadow: none;
    margin-bottom: 10px;
    width: 100%;
    font-size: 1.1em;
}
.chikka-options > .item-details > button.cancel{
    background-color: var(--RED_PALETTE);
}
.chikka-options > .item-details > button.complete{
    background-color: var(--GREEN_PALETTE);
}
.chikka-options > .item-details > .ratings{
    width: calc(100%);
    height: auto;
    /* background-color: var(--BG_WHITE_COLOR); */
    border-radius: 10px;
    margin-bottom: 15px;
}
.chikka-options > .item-details > .ratings > .title{
    background-color: var(--BG_WHITE_COLOR);
    width: 100%;
    padding: 5px 0px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 10px 10px 0px 0px;
}
.chikka-options > .item-details > .ratings > .widget{
    width: calc(100% - 20px);
    padding: 5px 10px 10px 10px;
    background-color: var(--BG_WHITE_COLOR);
    margin-bottom: 10px;
}

.chikka-options > .item-details > .ratings > .widget > .star-container{
    /* margin-top: 10px; */
}
.chikka-options > .item-details > .ratings > .widget > button{
    width: 100%;
    padding: 5px 0px;
    margin-top: 5px;
    box-shadow: none;
}
.chikka-options > .item-details > .ratings > .widget svg{
    margin: 0px 5px;
    padding: 5px;
    /* color: var(--MAIN_COLOR_HALF); */
    color: rgba(200, 200, 200, 0.5);
    /* background-color: red; */
}
.chikka-options > .item-details > .ratings > .widget svg.active{
    color: var(--MAIN_COLOR);
}
.chikka-options > .item-details > .ratings > .footer{
    background-color: var(--BG_WHITE_COLOR);
    width: 100%;
    padding: 5px 0px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 0px 0px 10px 10px;
    font-size: 0.7em;
}































.blank-widget{
    min-height: 30px;
    width: 100%;
    /* background-color: black; */
    margin-bottom: 10px;
    color: white;
}











@media (max-width: 767px) { /* mobile */

    .chikka-list{
        width: calc(100%);
        min-width: calc(100%);
        height: calc(100%);
        max-height: calc(100%);
        padding: 0px;
        background-color: white;
        position: absolute;
        top: 0px;
        left: 0px;
        border-right: none;

    }
    
    
    .chikka-list > .widget-container{
        
        width: calc(100% - 20px);
        height: auto;
        max-height: calc(100% - 40px);
        padding: 0px 10px;
        overflow: hidden;
        position: relative;
        overflow-y: overlay;
        margin-top: 10px;
    }
    .chikka-list > .widget-container > .list-widget{
        width: calc(100% - 20px);
        padding: 10px;

        
    }

    .chikka-message{
        width: calc(100%);
        height: calc(100%);
        background-color: white;
        overflow: hidden;
        overflow-y: auto;
    }

    .chikka-message > .message-container > .message-widget{
        width: calc(100%);
        height: auto;
        padding: 5px 0px;
        /* background-color: red; */
        font-size: 0.9em;
    }

    .chikka-options{
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
        background-color: white;
        position: absolute;
        right: 0px;
        top: 0px;
        border-left: none;
    }
    

}
