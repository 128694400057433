.chikkas-wrapper{
    width: calc(100vw);
    height: auto;
}

.chikkas-container{
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 78px);
    max-height: calc(100vh - 78px);

    /* background-color: blue; */
    position: relative;
}


.chikka-list{
    width: 400px;
    min-width: 400px;
    height: calc(100vh - 98px);
    max-height: calc(100vh - 98px);
    background-color: white;
    border-right: thin solid rgba(128, 128, 128, 0.5);
    z-index: 2;
}



.chikka-list > .header{
    width: calc(100% - 20px);
    /* border-bottom: thin solid rgba(128, 128, 128); */
    padding: 10px;
    cursor: pointer;
    /* background-color: red; */
}
.chikka-list > .header > img{
    height: 30px;
    width: 30px;
    object-fit: cover;
    margin-right: 5px;
}
.chikka-list > .header > span{
    font-size: 1.2em;
}

.chikka-list > .search{
    width: calc(100% - 20px);
    height: auto;
    padding: 0px 10px;
    /* border-bottom: thin solid rgba(128, 128, 128); */
    padding-bottom: 10px;
    margin-top: 10px;
}
.chikka-list > .search > .search-con{
    position: relative;
}
.chikka-list > .search > .search-con > input{
    width: calc(100% - 40px);
    padding: 10px 40px 10px 10px;
    font-size: 1.1em;
    border: none;
    border-radius: 10px;
    background-color: var(--BG_WHITE_COLOR);
}
.chikka-list > .search > .search-con > svg{
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    cursor: pointer;
}


.chikka-list > .widget-container{
    
    width: calc(400px);
    height: auto;
    max-height: calc(100% - 40px);
    overflow: hidden;
    position: relative;
}
.chikka-list > .widget-container:hover{
    overflow-y: overlay;
}

.chikka-list > .widget-container::-webkit-scrollbar {
    width: 5px;
    height: 3px;
}
.chikka-list > .widget-container::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.07); 
    border-radius: 5px;
}
.chikka-list > .widget-container::-webkit-scrollbar-thumb {
    background: var(--MAIN_COLOR_HALF); 
    border-radius: 5px;
    cursor: grab;
}
.chikka-list > .widget-container::-webkit-scrollbar-thumb:hover {
    background: var(--MAIN_COLOR); 
}


.chikka-list > .widget-container > .list-widget{
    width: calc(100% - 40px);
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
}
.chikka-list > .widget-container > .list-widget.active{
    background-color: var(--BG_WHITE_COLOR);
}
.chikka-list > .widget-container > .list-widget:hover{
    background-color: var(--BG_WHITE_COLOR);
}
.chikka-list > .widget-container > .list-widget.unread{
    font-weight: 500;
}
.chikka-list > .widget-container > .list-widget:last-child{
    margin-bottom: 0px;
}

.chikka-list > .widget-container > .list-widget > .chat-logo{
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 50%;
}
.chikka-list > .widget-container > .list-widget > .business-logo{
    position: absolute;
    bottom: 10px;
    height: 20px;
    width: 20px;
    object-fit: cover;
    border-radius: 50%;
}
.chikka-list > .widget-container > .list-widget > .details{
    height: 100%;
    width: calc(100% - 100px);
    /* background-color: red; */
    margin-left: 10px;
}
.chikka-list > .widget-container > .list-widget > .details > .title{
    max-width: calc(100%);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.chikka-list > .widget-container > .list-widget > .details > .subtitle{
    color: grey;
    font-weight: normal;
    width: 100%;
    /* background-color: red; */
}
.chikka-list > .widget-container > .list-widget > .details > .subtitle > .message{
    width: 80%;
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.chikka-list > .widget-container > .list-widget > .details > .subtitle > .date{
    width: calc(20% - 10px);
    /* background-color: blue; */
}
.chikka-list > .widget-container > .list-widget > .details > .user-icons{
    width: 100%;
    height: 20px;
    /* background-color: red; */
}
.chikka-list > .widget-container > .list-widget > .details > .user-icons > span{
    /* font-size: ; */
    color: grey;
}
.chikka-list > .widget-container > .list-widget > .details > .user-icons > img{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
}
.chikka-list > .widget-container > .list-widget > .indicators{
    width: 30px;
    height: 100%;
    /* background-color: red; */
}
.chikka-list > .widget-container > .list-widget > .indicators > .image-indicator{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    object-fit: cover;
}
.chikka-list > .widget-container > .list-widget > .indicators > .icon-indicator{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    object-fit: cover;
    color: grey;
}
.chikka-list > .widget-container > .list-widget > .indicators > .dot-indicator{
    padding: 10px;
    border-radius: 50%;
    background-color: black;
}


.chikka-list > .widget-container > .list-widget > .indicators > .dot-indicator.error{
    background-color: var(--RED_PALETTE);
}
.chikka-list > .widget-container > .list-widget > .indicators > .dot-indicator.active{
    background-color: var(--GREEN_PALETTE);
}
.chikka-list > .widget-container > .list-widget > .indicators > .dot-indicator.unread{
    background-color: var(--BLUE_PALETTE);
}







.chikka-message{
    width: calc(100% - 400px);
    height: calc(100% - 20px);
    background-color: white;

    
    z-index: 1;
}
.chikka-message > .header{
    width: calc(100% - 20px);
    height: 50px;
    padding: 10px;
    /* background-color: red; */
    /* border-bottom: thin solid rgba(128, 128, 128, 0.2); */
    box-shadow: 0px 1px 3px 0px rgba(150, 150, 150, 0.2);
    position: relative;
}
.chikka-message > .header > .actions{
    width: 120px;
    height: 100%;
    /* background-color: blue; */
}
.chikka-message > .header > .actions > .sbg{
    min-height: 50px;
    min-width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.chikka-message > .header > .actions > .sbg:hover{
    background-color: var(--BG_WHITE_COLOR);
}
.chikka-message > .header > .actions > .sbg > svg{
    color: var(--MAIN_COLOR);
    height: 20px;
    width: 20px;
    padding: 10px;
}
.chikka-message > .header > .profile{
    width: calc(100% - 130px);
    height: 100%;
}
.chikka-message > .header > .profile > img{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}
.chikka-message > .header > .profile > .title{
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.chikka-message > .header > .verify{
    position: absolute;
    top: 70px;
    left: 0px;
    height: auto;
    width: calc(100% - 20px);
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px;
    backdrop-filter: blur(6px);
    box-shadow: 0px 1px 3px 0px rgba(150, 150, 150, 0.2);
    padding-bottom: 20px;
}
.chikka-message > .header > .verify > .title{
    width: 100%;
    text-align: center;
}
.chikka-message > .header > .verify .action{
    margin-top: 10px;
}
.chikka-message > .header > .verify .action button{
    padding: 10px 30px;
}
.chikka-message > .header > .verify .more{
    font-size: 0.7em;
    position: absolute;
    bottom: 0px;
    right: 10px;
}

.chikka-message > .message-sender{
    height: auto;
    min-height: 50px;
    width: calc(100% - 20px);
    /* background-color: brown; */
    position: relative;
    padding: 0px 10px;
}
.chikka-message > .message-sender > .emoji-picker-react{
    position: absolute;
    bottom: 50px;
    left: 0px;
    width: 100%;
    height: calc(76.6vh);
    box-shadow: none;
}
.chikka-message > .message-sender > .emoji-picker-react button{
    box-shadow: none;
}
.chikka-message > .message-sender > .form{
    width: calc(100% - 40px);
    height: auto;
    position: relative;
}
.chikka-message > .message-sender > .form > .sender{
    width: calc(100% - 20px);
    height: 25px;
    background-color: var(--BG_WHITE_COLOR);
    font-size: 1 em;
    resize: none;
    border: none;
    border-radius: 10px;
    padding: 5px 40px 5px 10px;
    font-size: 1em;
    font-family: slim;
}
.chikka-message > .message-sender > .form > .sender::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
.chikka-message > .message-sender > .form > svg{
    position: absolute;
    right: 10px;
    
}
.chikka-message > .message-sender > svg{
    width: 30px;
    height: 30px;
    color: var(--MAIN_COLOR);
}

.chikka-message > .message-container{
    height: calc(100% - 120px);
    max-height: calc(100% - 120px);
    width: calc(100% - 20px);
    padding: 0px 10px;
    /* background-color: blue; */

    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}

.chikka-message > .message-container > .message-time{
    width: 100%;
    text-align: center;
    color: grey;
    /* background-color: red; */
}
.chikka-message > .message-container > .message-widget{
    width: calc(80%);
    height: auto;
    padding: 5px 0px;
    /* background-color: red; */
    font-size: 0.9em;
}
.chikka-message > .message-container > .message-widget.user{
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
}
.chikka-message > .message-container > .message-widget.owner{
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.chikka-message > .message-container > .message-widget > img{
    height: 20px;
    width: 20px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 8px;
}
.chikka-message > .message-container > .message-widget > span{
    background-color: var(--BG_WHITE_COLOR);
    padding: 5px 10px;
    border-radius: 10px;
}
.chikka-message > .message-container > .message-widget.owner > span{
    background-color: var(--MAIN_COLOR);
    color: white;
}
.chikka-message > .message-container > .message-widget > .actions{
    width: 50px;
    min-width: 50px;
}
.chikka-message > .message-container > .message-widget > .actions > svg{
    height: 20px;
    width: 20px;
    margin-left: 0px;
    padding: 5px;
    color: rgb(199, 199, 199);
    cursor: pointer;
    display: none;
}
.chikka-message > .message-container > .message-widget:hover > .actions > svg{
    display: block;
}







.chikka-options{
    width: 350px;
    height: calc(100vh - 98px);
    max-height: calc(100vh - 98px);
    padding: 10px;
    background-color: white;
    position: absolute;
    right: 0px;
    top: 0px;
    overflow: hidden;
    overflow-y: auto;
    border-left: thin solid rgba(128, 128, 128, 0.5);
    z-index: 3;
}
.chikka-options::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.chikka-options > .close{
    position: absolute;
    top: 0px;
    right: 0px;
    height: 30px;
    width: 30px;
    padding: 10px;
}
/* .chikka-options > .item-details{
    width: 100%;
    height: auto;
}
.chikka-options > .item-details > .item-img{
    width: calc(100vw - 100px);
    height: calc(100vw - 100px);
    max-width: calc(350px - 100px);
    max-height: calc(350px - 100px);
    border-radius: 50%;
    object-fit: cover;
    background-color: var(--BG_WHITE_COLOR);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
}
.chikka-options > .item-details > .title{
    font-size: 1.2em;
} */


.chikka-options > .item-details{
    width: calc(100%);
    max-width: var(--MAX_WIDTH);
    height: auto;
    max-height: 90vh;
    /* min-height: 350px; */
    /* padding: 10px; */
    position: relative;
    background-color: white;
    /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5); */
    /* border-radius: 10px; */
    /* overflow: hidden; */
    /* overflow-y: auto; */
    margin-top: 50px;
}
.chikka-options > .item-details > .empty{
    width: 100%;
    padding: 20px 0px;
    /* background-color: rgb(200, 200, 200); */
    color: grey;
    border-radius: 10px;
    border: 2px dashed grey;
}
.chikka-options > .item-details > .title{
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
}

.chikka-options > .item-details > .widget{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}
.chikka-options > .item-details > .widget > .header{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    /* border-radius: 10px; */
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 5px;
    cursor: pointer;
}
.chikka-options > .item-details > .widget > .option{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    /* border-radius: 10px; */
    margin-bottom: 5px;
    color: grey;
    position: relative;
    overflow: hidden;
}
.chikka-options > .item-details > .widget > .option:last-child{
    border-radius: 0px 0px 10px 10px;
}
.chikka-options > .item-details > .widget > .option > .opwid{
    width: 100%;
    padding: 2.5px 0px;
}
.chikka-options > .item-details > .widget > .option > .opwid.subtotal{
    /* font-size: 0.8em; */
    border-top: thin solid rgba(130, 130, 130, 0.2);
    color: black;
}
.chikka-options > .item-details > .widget > .option > .opwid.subtotal > p{
    margin-right: 17px;
}
.chikka-options > .item-details > .widget > .option > .opwid > .action > svg{
    height: 12px;
    width: 12px;
    margin-left: 5px;
    cursor: pointer;
}
.chikka-options > .item-details > .widget > .option > .opwid > span{
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.chikka-options > .item-details > .option{
    width: calc(100% - 20px);
    padding: 10px;
    height: auto;
    background-color: var(--BG_WHITE_COLOR);
    margin-bottom: 15px;
    border-radius: 10px;
}
.chikka-options > .item-details > .option > .title{
    margin-bottom: 10px;
}
.chikka-options > .item-details > .option > .type{
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    /* margin: 10px 0px; */
    margin-bottom: 10px;
}
.chikka-options > .item-details > .option > .type > span{
    
    background-color: grey;
    color: rgb(200, 200, 200);
    width: calc(50% - 1px);
    text-align: center;
    padding: 3px 0px;
}
.chikka-options > .item-details > .option > .type > span.active{
    background-color: var(--MAIN_COLOR);
    color: white;
}
.chikka-options > .item-details > .option > input{
    width: calc(100% - 10px);
    font-size: 1.1em;
    border: none;
    border-radius: 5px;
    padding: 3px 0px 3px 10px;
}
.chikka-options > .item-details > .option > input:disabled{
    background-color: white;
}
.chikka-options > .item-details > .option > button{
    width: 100%;
    margin-top: 10px;
    box-shadow: none;
    font-size: 1.1em;
}
.chikka-options > .item-details > .option > .subtitle{
    margin-bottom: 5px;
}
.chikka-options > .item-details > .option > .subtitle.discount{
    color: var(--GREEN_PALETTE);
}
.chikka-options > .item-details > .note{
    /* height: 30px; */
    width: calc(100% - 20px);
    padding: 0px 10px;
    font-size: 0.7em;
    margin-bottom: 10px;
    /* background-color: red; */
}
.chikka-options > .item-details > button{
    box-shadow: none;
    margin-bottom: 10px;
    width: 100%;
    font-size: 1.1em;
}
.chikka-options > .item-details > button.cancel{
    background-color: var(--RED_PALETTE);
}
.chikka-options > .item-details > button.complete{
    background-color: var(--GREEN_PALETTE);
}
.chikka-options > .item-details > .ratings{
    width: calc(100%);
    height: auto;
    /* background-color: var(--BG_WHITE_COLOR); */
    border-radius: 10px;
    margin-bottom: 15px;
}
.chikka-options > .item-details > .ratings > .title{
    background-color: var(--BG_WHITE_COLOR);
    width: 100%;
    padding: 5px 0px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 10px 10px 0px 0px;
}
.chikka-options > .item-details > .ratings > .widget{
    width: calc(100% - 20px);
    padding: 5px 10px 10px 10px;
    background-color: var(--BG_WHITE_COLOR);
    margin-bottom: 10px;
}

.chikka-options > .item-details > .ratings > .widget > .star-container{
    /* margin-top: 10px; */
}
.chikka-options > .item-details > .ratings > .widget > button{
    width: 100%;
    padding: 5px 0px;
    margin-top: 5px;
    box-shadow: none;
}
.chikka-options > .item-details > .ratings > .widget svg{
    margin: 0px 5px;
    padding: 5px;
    /* color: var(--MAIN_COLOR_HALF); */
    color: rgba(200, 200, 200, 0.5);
    /* background-color: red; */
}
.chikka-options > .item-details > .ratings > .widget svg.active{
    color: var(--MAIN_COLOR);
}
.chikka-options > .item-details > .ratings > .footer{
    background-color: var(--BG_WHITE_COLOR);
    width: 100%;
    padding: 5px 0px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 0px 0px 10px 10px;
    font-size: 0.7em;
}































.blank-widget{
    min-height: 30px;
    width: 100%;
    /* background-color: black; */
    margin-bottom: 10px;
    color: white;
}











@media (max-width: 767px) { /* mobile */

    .chikka-list{
        width: calc(100%);
        min-width: calc(100%);
        height: calc(100%);
        max-height: calc(100%);
        padding: 0px;
        background-color: white;
        position: absolute;
        top: 0px;
        left: 0px;
        border-right: none;

    }
    
    
    .chikka-list > .widget-container{
        
        width: calc(100% - 20px);
        height: auto;
        max-height: calc(100% - 40px);
        padding: 0px 10px;
        overflow: hidden;
        position: relative;
        overflow-y: overlay;
        margin-top: 10px;
    }
    .chikka-list > .widget-container > .list-widget{
        width: calc(100% - 20px);
        padding: 10px;

        
    }

    .chikka-message{
        width: calc(100%);
        height: calc(100%);
        background-color: white;
        overflow: hidden;
        overflow-y: auto;
    }

    .chikka-message > .message-container > .message-widget{
        width: calc(100%);
        height: auto;
        padding: 5px 0px;
        /* background-color: red; */
        font-size: 0.9em;
    }

    .chikka-options{
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        padding: 10px;
        background-color: white;
        position: absolute;
        right: 0px;
        top: 0px;
        border-left: none;
    }
    

}