
.custom-form .field{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.custom-form .field > div{
    width: 100%;
}
.custom-form .field > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
}
.custom-form .field > span{
    width: 100%;
    text-align: start;
}
.custom-form .field > span > .red{
    color: red;
}
.custom-form .field > span > .purple{
    color: purple;
}
.custom-form .field > span > .premium{
    color: goldenrod;
}
/* .custom-form .field > span > .right{
color: red;
} */
.custom-form .field > iframe{
    width: 100%;
    height: 300px;
}
.custom-form .field > select{
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
}
.custom-form .field > textarea{
    width: calc(100% - 15px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
}
.custom-form > button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
}
.custom-form * button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
}
.custom-form .input-button-combo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
}
.custom-form .input-button-combo > button{
    width: 30%;
    font-size: 1.2em;
    border-radius: 0px 5px 5px 0px;
    box-shadow: none;
}
.custom-form .input-button-combo > input{
    width: calc(70% - 20px);
    border: none;
    padding: 5px 0px;
    padding-left: 20px;
    font-size: 1.2em;
    border-radius: 5px 0px 0px 5px;
    box-shadow: none;
    background-color: var(--BG_WHITE_COLOR);
    height: 100%;
}






.custom-form > .field input:active,
.custom-form > .field input:focus{
    /* border: thick solid blue; */
    border: none;
    outline: none;
}

.custom-form > .title{
    width: 100%;
    text-align: center;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}



.custom-form > .divider-message{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    position: relative;
    z-index: inherit;
}
.custom-form > .divider-message > hr{
    width: 100%;
    position: absolute;
    z-index: -5;
}
.custom-form > .divider-message > span{
    /* z-index: 5; */
    background-color: white;
    padding: 0px 10px;
    font-size: 0.8em;
}