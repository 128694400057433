@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');


:root{
  --MAX_WIDTH: 960px;

  
  /* --MAIN_COLOR: #fdcb30; */
  /* --MAIN_COLOR: #557C55; */
  --MAIN_COLOR: #C80F2E;
  
  /* --MAIN_COLOR_HALF: rgba(253, 202, 48, 0.5); */
  --MAIN_COLOR_HALF: rgba(85, 124, 85, 0.5);
  
  
  --SUB_COLOR: #fba516;
  --SHADE_COLOR: #f58511;
  

  --BTN_COLOR: #C80F2E;
  /* --BTN_COLOR: #718D71; */
  /* --BTN_COLOR: #ef1113; */
  /* --BTN_COLOR_HOVER: rgb(194, 151, 44); */
  --BTN_COLOR_HOVER: hsla(350, 86%, 42%, 0.5);
  --MESSAGE_MY_COLOR: #A6CF98;

  

  /* --BG_WHITE_COLOR: #FEFEFE; */
  --BG_WHITE_COLOR: #f7f7f7;

  
  --TEXT_COLOR: white;
  --TITLE_COLOR: #f58511;

  --ORANGE_PALETTE: #d15421;
  --GREEN_PALETTE: #7FA559;
  --PINK_PALETTE: #BA447F;
  --RED_PALETTE: #B24C4C;
  --YELLOW_PALETTE: #B2B24C;
  --PURPLE_PALETTE: #5959A5;
  --BLUE_PALETTE: #59A5A5;


  
}

@font-face {
  font-family: 'avant-garde-lg';
  src: url("./font/AvantGardeLG.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'avant-garde-md';
  src: url("./font/AvantGardeMD.otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'slim';
  src: url("./font/slim.woff2");
  font-weight: normal;
  font-style: normal;
}


/* base styles */
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
}
ul, li, p, h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}


#root::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* position: relative;
  right: 20px; */
}
/* Track */
#root::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.07);  */
  background: var(--BTN_COLOR); 
  border-radius: 5px;
}
/* Handle */
#root::-webkit-scrollbar-thumb {
  /* background: #888;  */
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
/* Handle on hover */
#root::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
  background: var(--BTN_COLOR_HOVER); 
}



.flex-row-center-center{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flex-row-center-start{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.flex-row-center-end{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.flex-row-center-between{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.flex-row-center-around{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.flex-row-center-even{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.flex-row-start-center{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.flex-row-start-start{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-row-start-end{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-row-start-between{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-row-start-around{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}
.flex-row-start-even{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
}

.flex-row-end-center{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
.flex-row-end-start{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.flex-row-end-end{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-row-end-between{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-row-end-around{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-around;
}
.flex-row-end-even{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
}



.flex-col-center-center{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.flex-col-center-start{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.flex-col-center-end{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.flex-col-center-between{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.flex-col-center-around{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.flex-col-center-even{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.flex-col-start-center{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.flex-col-start-start{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-col-start-end{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.flex-col-start-between{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.flex-col-start-around{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.flex-col-start-even{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.flex-col-end-center{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.flex-col-end-start{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}
.flex-col-end-end{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.flex-col-end-between{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.flex-col-end-around{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}
.flex-col-end-even{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}






button, .btn{
  background-color: var(--BTN_COLOR);
  color: white;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.5);
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
}
button:active{
  background-color: var(--BTN_COLOR_HOVER);
}


progress {
  border-radius: 3px; 
  width: 80%;
}
progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  border-radius: 0px 5px 5px 0px;
}
progress::-webkit-progress-value {
  background-color: goldenrod;
  border-radius: 0px 5px 5px 0px;
}
progress::-moz-progress-bar {
/* style rules */
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  border-radius: 0px 5px 5px 0px;
}
progress::-moz-progress-value {
  background-color: goldenrod;
  border-radius: 0px 5px 5px 0px;
}


*:focus {
  outline: 0;
}
*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}


*[message] {
  position: relative;
  color: #00F;
  cursor: help;
}

*[message]:hover::after,
*[message]:focus::after {
  content: attr(message);
  position: absolute;
  left: 0;
  top: 24px;
  min-width: 200px;
  border-radius: 10px;
  background-color: var(--BG_WHITE_COLOR);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  padding: 12px;
  color: #000000;
  font-size: 14px;
  z-index: 999999;
  font-family: slim;
  letter-spacing: 1.2px;
  line-height: 1.3em;
}




/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /* position: relative;
  right: 20px; */
}
/* Track */
::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.07);  */
  background: var(--BG_WHITE_COLOR); 
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #888;  */
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
  background: var(--BTN_COLOR); 
}




.pdftest{
  height: 100%;
  width: 100%;
  text-align: center;
}
.pdftestError{
  padding: 10px;
}

.error{
  color: var(--RED_PALETTE);
}
.alert{
  color: var(--RED_PALETTE);
}.info{
  color: var(--BLUE_PALETTE);
}

.w100{
  width: 100%;
}

.webcat{
  /* background-color: black; */
  width: 100%;
}
.webcat > *{
  width: 100%;
}