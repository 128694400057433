.modal-container-backdrop{
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100000000000;
    background-color: rgba(255, 255, 255, 0.164);
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container{
    max-width: 50vw;
    min-width: 250px;
    max-height: 80vh;
    padding: 30px 50px;
    background-color: white;
    /* margin-top: 0vh; */
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;

    overflow: hidden;
    overflow-y: auto;
}
.modal-container > .modal-close{
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.modal-container form{
    min-width: 500px;
}
.modal-container p{
    width: 100%;
    text-align: start;
    margin-bottom: 10px;
}
.modal-container span.title{
  width: 100%;
  text-align: start;
  margin-bottom: 10px;
  font-size: 1.1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.modal-container span.title > *{
  padding-left: 5px;
  font-size: 0.8em;
}
.modal-container .field{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .modal-container .field > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  .modal-container .field > span{
      width: 100%;
      text-align: start;
  }
  .modal-container .field > span > .red{
    color: red;
  }
  .modal-container .field > span > .purple{
    color: purple;
  }
  .modal-container .field > span > .premium{
    color: goldenrod;
  }
  /* .modal-container .field > span > .right{
    color: red;
  } */
  .modal-container .field > iframe{
    width: 100%;
    height: 300px;
  }
  .modal-container .field > select{
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
  }
  .modal-container > button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
  }
  .modal-container * button{
    width: 100%;
    font-size: 1.2em;
    margin-top: 10px;
  }
  .modal-container .input-button-combo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-bottom: 10px;
  }
  .modal-container .input-button-combo > button{
    width: 30%;
    font-size: 1.2em;
    border-radius: 0px 5px 5px 0px;
    box-shadow: none;
  }
  .modal-container .input-button-combo > input{
    width: calc(70% - 20px);
    border: none;
    padding: 5px 0px;
    padding-left: 20px;
    font-size: 1.2em;
    border-radius: 5px 0px 0px 5px;
    box-shadow: none;
    background-color: var(--BG_WHITE_COLOR);
    height: 100%;
  }
  .modal-container .singleImage{
    height: auto;
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
  }
  .modal-container .singleImage img{
    max-width: 100%;
    max-height: 60vh;
  }


  .modal-container .field-checkbox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: between;
    width: 100%;
    /* background-color: red; */
  }
  .modal-container .field-checkbox > label > input{
    min-width: 20px;
  }
  .modal-container .field-checkbox > label{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: blue; */
  }
  .modal-container .field-checkbox > label > span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 40px);
    position: relative;
    /* background-color: red; */
  }
  .modal-container .field-checkbox > label > span > label{
    width: auto;
    font-size: 0.9em;
    position: absolute;
    right: 0px;
    /* height: 100%; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* background-color: purple; */
  }
  .modal-container .field-checkbox > p{
    margin-left: 15%;
    color: grey;
  }

  @media (max-width: 768px) { /* mobile */
    :root{
        --HTITLE_SIZE: 3vw;
       
    }

    .modal-container{
      max-width: calc(10vw - 150px);
      min-width: 75vw;
      padding: 30px 30px;
      background-color: white;
      margin-top: 10vh;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.2);
      text-align: center;
      position: relative;
  }
  .modal-container form{
      min-width: 100%;
  }
  .modal-container .field-checkbox > p{
    margin-left: 30%;
  }


    
    
    
    

}