@import "~react-image-gallery/styles/css/image-gallery.css";

.wizard-container{
    width: 100%;
    height: auto;
    /* min-height: 350px; */
    /* position: relative; */
    z-index: 0;
    /* background-color: brown; */
    position: relative;
}

.wizard-wand{
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
    margin-top: 10px;
}

.wizard-wand > .content{
    width: 100%;
    height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    /* background-color: red; */
    overflow: hidden;
    overflow-y: auto;
}

.wizard-wand > .nav{
    height: 60px;
    width: 100%;
    color: var(--BTN_COLOR);
    font-size: 1.6em;
    border-top: thin solid rgba(0, 0, 0, 0.1);
    /* background-color: white; */
    /* border-radius: 15px 15px 0px 0px; */
}
.wizard-wand > .nav > img{
    padding: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    border-radius: 15px;
    background-color: var(--MAIN_COLOR_HALF);
}
.wizard-wand > .nav > img:hover{
    background-color: rgba(128, 128, 128, 0.2);
}
.wizard-wand > .nav > img.active{
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
    background-color: var(--MAIN_COLOR);
}




.items-header-nav{
  width: 100%;
  max-width: var(--MAX_WIDTH);
  height: auto;
  margin-bottom: 10px;
}
.items-header-nav > div{
  background-color: rgba(128, 128, 128, 0.5);
  height: 40px;
  padding: 0px 10px;
  color: white;
  cursor: pointer;
}
.items-header-nav > div.active{
  background-color: var(--MAIN_COLOR);
  height: 40px;
  padding: 0px 10px;
  color: white;
  cursor: pointer;
}
.items-header-nav > .grid img{
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.items-header-nav > .list img{
  height: 35px;
  width: 35px;
  margin-left: 10px;
}
.items-header-nav > .divider{
  width: 1px;
}


.items-header-search{
  width: calc(100% - 20px);
  max-width: var(--MAX_WIDTH);
  height: auto;
  background-color: var(--BG_WHITE_COLOR);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 10px;
}
.items-header-search input{
  width: calc(100% - 50px);
  height: 100%;
  border: none;
  background-color: var(--BG_WHITE_COLOR);
  border-radius: 10px 0px 0px 10px;
  font-size: 1.2em;
  padding: 5.5px 0px;
  padding-left: 10px;
  /* background-color: blue; */
}
.items-header-search img{
  height: 30px;
  padding: 5px 10px;
  /* background-color: red; */
}



.items-container{
  width: 100%;
  display: block;
  height: auto;
}
.items-container > .widget{
  height: 200px;
  width: calc(( 100% / 2 ) - 22px);
  max-width: 170px;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  float: left;
  background-color: var(--MAIN_COLOR);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  text-decoration: none;
  position: relative;
}
.items-container > .widget > img{
  height: 170px;
  width: calc(100% - 2px);
  object-fit: cover;
  cursor: pointer;
  border: thin solid var(--MAIN_COLOR);
  border-radius: 10px 10px 0px 0px;
  background-color: var(--BG_WHITE_COLOR);
}
.items-container > .widget > span{
  max-width: 90%;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.items-container > .widget.add{
  justify-content: center;
  border-radius: 10px;
}
.items-container > .widget.add > img{
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  background-color: var(--MAIN_COLOR);
}
.items-container > .widget.add > span{
  font-size: 1.4em;
}
.item-wizard{
  width: 100%;
  height: calc(100vh - 150px);
  max-height: calc(100vh - 150px);
  /* background-color: red; */
  position: absolute;
  top: 0px;
  left: 0px;
}


.items-container-list{
  width: 100%;
}
.items-container-list > a{
  /* background-color: red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: black;
  padding: 10px 0px;
  border-bottom: thin solid rgba(128, 128, 128, 0.5);
  position: relative;
}
.items-container-list > a > img{
  height: 50px;
  width: 50px;
  object-fit: cover;
  background-color: var(--MAIN_COLOR);
  margin-right: 10px;
}
.items-container-list > a.add > img{
  border-radius: 50%;
}


.wizard-container .subtitle{
    font-size: 0.7em;
}
.wizard-container .field-single{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .wizard-container .field-single > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  .wizard-container .field-single > textarea{
    width: calc(100% - 15px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
  }
  
  
  .wizard-container .field-double{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .wizard-container .field-double input{
    width: calc(50% - 25px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  .wizard-container .field-double textarea{
    width: calc(50% - 25px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
  }
  .wizard-container button.submit{
    width: 100%;
    font-size: 1.4em;
  }
  
  .wizard-container .field > div.iframe{
    width: 100%;
    height: 300px;
    position: relative;
  }
  .wizard-container .field > div.iframe > i{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    z-index: 100;
    font-size: 2em;
    color: var(--BTN_COLOR);
    text-shadow: 1px 1px 3px black;
  }
  .wizard-container .field > .input-button-combo{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    /* font-size: 1.2em; */
    background-color: var(--BG_WHITE_COLOR);
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }
  .wizard-container .field > .input-button-combo > input{
    width: calc( 100% - 100px );
    height: 100%;
    border: none;
    background-color: var(--BG_WHITE_COLOR);
    font-size: 1.2em;
  }
  .wizard-container .field > .input-button-combo > button{
    width: 100px;
    font-size: 1.2em;
    padding: 0px;
  }



.wizard-widget.category{
  /* background-color: red; */
  min-height: calc(100vh - 200px);

}



/* .wizard-widget.images{
 
} */

.wizard-widget.images > .wizard-imgal{
  width: 100%;
  position: relative;
  /* max-height: 50vh; */

  /* add this line to view all of the image, not cropped */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.wizard-widget.images > .wizard-imgal .loader{
  width: calc(100% - 20px);
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}
.wizard-widget.images > .wizard-imgal .imgal-delete{
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 30px;
  width: 30px;
  background-color: var(--RED_PALETTE);
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.wizard-widget.images > .wizard-imgal .imgal-star{
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  
  background-color: var(--MAIN_COLOR);
  padding: 5px;
  /* padding: 5px; */
  border-radius: 50%;
  /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5); */
  opacity: 0.4;
  z-index: 10;
  cursor: pointer;
}
.wizard-widget.images > .wizard-imgal .imgal-star:hover{
  opacity: 0.6;
  /* box-shadow: 0px 0px 5px 0px rgb(0, 0, 0); */
}

.wizard-widget.images > .wizard-imgal .imgal-star.active{
  opacity: 1;
  transform: scale(1.2);
  /* background-color: red; */
}
.wizard-widget.images > .wizard-imgal .imgal-star.active:hover{
  opacity: 1;
  box-shadow: 0px 0px 5px 0px rgb(0, 0, 0);
  background-color: rgba(184, 184, 184, 0.1);
  backdrop-filter: blur(1px);
}

.wizard-widget.images > .wizard-imgal .image-gallery-slides{
  max-height: 50vh;
}
.wizard-widget.images > .wizard-imgal .image-gallery-slides img{
  max-height: 50vh;
  object-fit: cover;
}
.wizard-widget.images > .wizard-imgal .image-gallery-slide-wrapper > button{
   box-shadow: none;
}
.wizard-widget.images > .wizard-imgal .image-gallery-slide-wrapper > button:hover{
  color: var(--MAIN_COLOR);
  background-color: rgba(169, 169, 169, 0.1);
}



/* .wizard-widget.price{
  
} */
.price-create > button{
  border-radius: 5px 5px 0px 0px;
}
.price-create-popup{
  background-color: white;
  padding: 10px;
  border: thin solid rgba(128, 128, 128, 0.2);
  border-top: none;
}
/* .price-widget-con{

} */

.price-widget{
  width: calc(100% - 20px);
  /* background-color: white; */
  padding: 5px 10px;
  margin-top: 10px;
  border-bottom: thin solid rgba(128, 128, 128, 0.2);
}
.price-widget > .header{
  /* background-color: blue; */
  margin-bottom: 5px;
}
.price-widget > .header input{
  border: none;
  background-color: transparent;
  padding: 3px 5px;
  font-size: 1.1em;
  border-radius: 5px;
  width: calc( 100% - 40px );
  max-width: calc( 100% - 40px );
  /* font-weight: bold; */
}
.price-widget > .header .inputHolder{
  font-size: 1.1em;
  border-radius: 5px;
  width: calc( 100% - 40px );
  max-width: calc( 100% - 40px );
}
.price-widget > .header img{
  height: 20px;
  /* background-color: red; */
  padding: 4.5px;
}

.price-widget > .editable textarea{
  resize: none;
  border: none;
  border-radius: 5px;
  width: calc(100% - 10px);
  font-size: 0.9em;
  font-family: Poppins, sans-serif;
  padding: 5px;
  margin-bottom: 5px;
  background-color: transparent;
}
.price-widget > .editable input{
  border: none;
  background-color: transparent;
  padding: 3px 5px;
  font-size: 1.1em;
  border-radius: 5px;
  width: calc( 100% - 40px );
  max-width: calc( 100% - 40px );
}


.price-widget > .editable img{
  height: 20px;
  /* background-color: red; */
  padding: 4.5px;
}



.price-widget.onEdit input,
.price-widget.onEdit textarea{
  background-color: var(--BG_WHITE_COLOR);
}





.availability-create-popup{
  background-color: white;
  padding: 10px;
  border: thin solid rgba(128, 128, 128, 0.2);
  border-top: none;
  height: auto;
}

.availability-widget{
  width: calc(100% - 20px);
  padding: 10px;
  /* border: thin solid rgba(128, 128, 128, 0.2); */
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
}

/* .availability-widget input{
  width: calc(100% - 15px);
  padding: 5px;
  padding-left: 10px;
  font-size: 1.2em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  border-radius: 5px;
} */
.availability-widget select{
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-size: 1em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  /* border-bottom: 1px solid black; */
  margin-bottom: 5px;
}
.availability-widget > .header img{
  height: 30px;
  width: 30px;
  padding: 5px;
}
.availability-widget > .editable > div{
  width: 100%;
}

.availability-widget > .editable > .double > .field input{
  width: calc(100% - 20px);
}
.availability-widget > .editable > label{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0px;
}
.availability-widget > .editable input[type="date"],
.availability-widget > .editable input[type="text"],
.availability-widget > .editable input[type="time"]{
  width: calc(100% - 15px);
  padding: 5px;
  padding-left: 10px;
  font-size: 1.2em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
}
.availability-widget > .editable > label > span{
  /* white-space: nowrap; */
  width: auto;
  background-color: red;
}








@media (max-width: 767px) { /* mobile */



    .wizard-container{
        width: 100%;
        height: auto;
        /* min-height: 350px; */
        /* position: relative; */
        z-index: 0;
        /* background-color: brown; */
        position: relative;
    }
    
    .wizard-wand{
        width: calc(100% - 20px);
        max-width: var(--MAX_WIDTH);
        margin-top: 10px;
    }
    
    .wizard-wand > .content{
        width: 100%;
        max-width: 100%;
        height: auto;
        max-height: unset;
        /* background-color: green; */
        overflow: hidden;
        overflow-y: unset;
        margin-bottom: 10px;
    }
    
    .wizard-wand > .nav{
        height: auto;
        width: 60px;
        font-size: 1.6em;
        border-top: none;
        position: fixed;
        right: 0px;
        bottom: 12vh;
        background-color: var(--MAIN_COLOR_HALF);
        backdrop-filter: blur(6px);
        border-radius: 15px 0px 0px 15px;
        padding: 10px 5px 5px 5px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
        z-index: 99999999999999999999999999999;
    }
    .wizard-wand > .nav > img{
        padding: 10px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        background-color: unset;
    }
    .wizard-wand > .nav > img:last-child{
        border-top: thin solid rgba(0, 0, 0, 0.1);
        height: 40px;
        width: 40px;
        padding: 5px;
        margin-top: 10px;
        border-radius: 0px;
    }
    
    
    .wizard-wand  > .sidebarOpener{
        position: fixed;
        right: 0px;
        bottom: 15vh;
        bottom: 150px;
        height: 150px;
        width: 10px;
        background-color: var(--MAIN_COLOR_HALF);
        border-radius: 15px 0px 0px 15px;
    }
    .wizard-wand  > .sidebarOpener > .h{
        height: 80%;
        width: 1px;
        /* background-color: rgba(136, 136, 136, 0.2); */
        background-color: var(--MAIN_COLOR_HALF);
        box-shadow: 1px 0px 5px rgba(255, 255, 255, 0.5);
        position: relative;
        left: -2px;
    }

    .wizard-widget.images > .wizard-imgal .image-gallery-slides{
      max-height: calc(100vw - 20px);
    }
    .wizard-widget.images > .wizard-imgal .image-gallery-slides img{
      max-height: calc(100vw - 20px);
      object-fit: cover;
    }
    .wizard-widget.images > .wizard-imgal .image-gallery-slide-wrapper > button{
      display: none;
    }


}