/* .business-manage-main-container{

} */

#business-manage{
    /* background-color: red; */
    width: calc(100% - 20px);
    max-width: var(--MAX_WIDTH);
  }
  
  
  #business-manage .field-single{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  #business-manage .field-single > input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  #business-manage .field-single > textarea{
    width: calc(100% - 15px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
  }
  
  
  #business-manage .field-double{
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  #business-manage .field-double input{
    width: calc(50% - 25px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
  }
  #business-manage .field-double textarea{
    width: calc(50% - 25px);
    height: 100px;
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    resize: none;
  }
  #business-manage button.submit{
    width: 100%;
    font-size: 1.4em;
  }
  
  #business-manage .field > div.iframe{
    width: 100%;
    height: 300px;
    position: relative;
  }
  #business-manage .field > div.iframe > i{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    z-index: 100;
    font-size: 2em;
    color: var(--BTN_COLOR);
    text-shadow: 1px 1px 3px black;
  }
  #business-manage .field > .input-button-combo{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    /* font-size: 1.2em; */
    background-color: var(--BG_WHITE_COLOR);
    /* border-bottom: 1px solid black; */
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
  }
  #business-manage .field > .input-button-combo > input{
    width: calc( 100% - 100px );
    height: 100%;
    border: none;
    background-color: var(--BG_WHITE_COLOR);
    font-size: 1.2em;
  }
  #business-manage .field > .input-button-combo > button{
    width: 100px;
    font-size: 1.2em;
    padding: 0px;
  }
  
  #business-manage > .nav{
      width: 100%;
      z-index: 1;
  }
  #business-manage > .nav > span{
    width: calc( (100% / 5) - 5px );
    background-color: var(--BTN_COLOR);
    /* font-size: 1em; */
    font-weight: bold;
    padding: 5px 0px;
    text-align: center;
    color: var(--TEXT_COLOR);
    cursor: pointer;
    border-radius: 5px;
  }
  #business-manage > .nav > a.active{
    /* box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2); */
    font-size: 1.2em;
    border-bottom: thin solid black;
  }
  #business-manage > .nav > a:active{
    background-color: var(--BTN_COLOR_HOVER);
  }
  #business-manage > .nav.mobile{
      position: fixed;
      bottom: 0px;
      background-color: white;
      padding: 10px;
  }
  #business-manage > .nav.mobile > img{
    background-color: var(--MAIN_COLOR);
    /* background-color: var(--BG_WHITE_COLOR); */
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    height: 30px;
    width: 30px;
  }
  #business-manage > .nav.mobile > img.super{
    height: 50px;
    width: 50px;
    padding: 5px;
  }
  #business-manage > .nav.mobile > img.active{
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
    
  }
  
 .business-manage-container{
    width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
    margin-bottom: 100px;
  }
  
  
.business-manage-container > .store-profile{
  height: 200px;
  width: 100%;
  position: relative;
  border-radius: 15px;
  
}
.business-manage-container > .store-profile > .store-background{
  height: 100%;
  width: 100%;
  /* position: absolute; */
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
    overflow: hidden;
}
.business-manage-container > .store-profile > .store-background > img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  cursor: pointer;
  border-radius: 10px;
}
.business-manage-container > .store-profile > .store-background > .fg{
  height: 50px;
  width: 100%;
  z-index: 1;
  background-color: rgba(128, 128, 128, 0.5);
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}
.business-manage-container > .store-profile > .store-image{
  height: 100px;
  width: 100px;
  position: absolute;
  bottom: -25px;
  left: 10px;
  background-color: white;
  z-index: 5;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}
.business-manage-container > .store-profile > .store-image > img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
  cursor: pointer;
}
.business-manage-container > .store-profile > .store-image > .fg{
  height: 25px;
  width: 100%;
  z-index: 1;
  background-color: rgba(128, 128, 128, 0.5);
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  color: white;
  /* font-size: 1.2em; */
  font-weight: bold;
}

.business-manage-container > .store-name{
  margin-top: 10px;
  width: calc(100% - 100px);
  font-size: 1.6em;
  font-weight: bold;
  text-align: center;
  border: none;
  background-color: transparent;
  /* background-color: var(--BG_WHITE_COLOR); */

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  float: left;
}
.business-manage-container > .field{
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 10px;
}
.business-manage-container > .field > input{
  width: calc(100% - 15px);
  padding: 5px;
  padding-left: 10px;
  font-size: 1.2em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  /* border-bottom: 1px solid black; */
  border-radius: 5px;
}
.business-manage-container > .field > .input-button-combo{
  width: calc(100% - 15px);
  padding: 5px;
  padding-left: 10px;
  /* font-size: 1.2em; */
  background-color: var(--BG_WHITE_COLOR);
  /* border-bottom: 1px solid black; */
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}
.business-manage-container > .field > .input-button-combo > input{
  width: calc( 100% - 100px );
  height: 100%;
  border: none;
  background-color: var(--BG_WHITE_COLOR);
  font-size: 1.2em;
}
.business-manage-container > .field > .input-button-combo > .input{
    width: calc( 100% - 100px );
    height: 100%;
    border: none;
    background-color: var(--BG_WHITE_COLOR);
    font-size: 1.2em;
  }
.business-manage-container > .field > .input-button-combo > button{
  width: 100px;
  font-size: 1.2em;
  padding: 0px;
}

.business-manage-container > .field > .search{
    width: 100%;
    margin-bottom: 10px;
}
.business-manage-container > .field > .search input{
    width: calc(100% - 15px);
    padding: 5px;
    padding-left: 10px;
    font-size: 1.2em;
    background-color: var(--BG_WHITE_COLOR);
    border: none;
    border-radius: 5px;
}

.business-manage-container > .field > span > .red{
  color: red;
}
.business-manage-container > .field > span > .purple{
  color: purple;
}
.business-manage-container > .field > span > .premium{
  color: goldenrod;
}

.business-manage-container > .field > div.iframe{
  width: 100%;
  height: 300px;
  position: relative;
}
.business-manage-container > .field > div.iframe > img{
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px;
  z-index: 100;
  font-size: 2em;
  color: var(--BTN_COLOR);
  text-shadow: 1px 1px 3px black;
  /* background-color: red; */
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.business-manage-container > .field > select{
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-size: 1.2em;
  background-color: var(--BG_WHITE_COLOR);
  border: none;
  /* border-bottom: 1px solid black; */
}
.business-manage-container > button{
  width: 100%;
  font-size: 1.2em;
}











