
.store-wrapper{
    width: 100vw - 20px;
    height: auto;
    /* background-color: blue; */
    overflow: hidden;
}

.store-container{
    width: calc(100% - 20px);
    padding: 10px 0px;
    max-width: var(--MAX_WIDTH);
    height: auto;
    /* background-color: blue; */
}

.store-header{
    width: 100%;
    height: auto;
}
.store-header > .logo-banner{
    position: relative;
    height: 40vh;
    width: 100%;
}
.store-header > .logo-banner > .banner{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.store-header > .logo-banner > .logo{
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 5px;
    left: 5px;
    background-color: var(--BG_WHITE_COLOR);
    padding: 5px;
    border-radius: 10px;
    object-fit: cover;
}
.store-header > .logo-banner > .dineSafePass{
    position: absolute;
    bottom: 10px;
    right: 10px;
    max-height: 100px;
}
.store-header > .store-name{
    font-size: 1.4em;
    padding: 10px 10px 0px 10px;
}

.store-header.item-mode > .logo-banner{
    max-height: 100px;
    /* transition: max-height 0.5s ease-out; */
    /* transform: translateY(120); */
}
.store-header.item-mode > .logo-banner > .logo{
    max-height: 80px;
    max-width: 80px;
    /* transition: max-height 1s ease-out;  */

}
.store-header.item-mode > .logo-banner > .dineSafePass{
    max-height: 50px;
    /* transition: max-height 1.5s ease-out; */
}











.store-optional-content{
    width: 100%;
    height: auto;
    /* background-color: red; */
}
.store-optional-content > .store-name{
    font-size: 1.4em;
    font-weight: bold;
}
.store-optional-content > .details{
    font-size: 0.8em;
}
.store-optional-content > .details a{
    color: black;
    text-decoration: none;
}
.store-optional-content > .details img{
    height: 20px;
    margin: 0px 5px 5px 15px;

}

/* .store-optional-content > .details.desktop{
    display: block;
    height: auto;
}
.store-optional-content > .details.desktop *{
    float: left;
} */

.store-optional-content > .iframe{
    width: 100%;
    height: 300px;
    /* background-color: red; */
    /* position: relative; */
    /* margin-top: 10px; */
}
.store-optional-content > .iframe > img{
    position: absolute;
    top: 0px;
    right: 0px;
    /* z-index: 99999; */
    /* transform: scale(5); */
    display: none;
}
.store-optional-content > .title{
    font-size: 1.2em;
    margin-top: 10px;
}
.store-optional-content > .blank{
    width: 100%;
    height: 150px;
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 5px;
}

.store-category-nav{
    width: 100%;
    height: 40px;
    /* background-color: red; */
    margin-top: 10px;
    position: relative;
    background-color: white;
}
.store-category-nav > img{
    height: 30px;
    width: 30px;
    object-fit: cover;
    margin-right: 10px;
}
.store-category-nav > .cat-container{
    overflow: hidden;
    overflow-x: auto;
    height: 100%;
}

.store-category-nav > .cat-container::-webkit-scrollbar {
  width: 3px;
  height: 0px;
}
/* Track */
.store-category-nav > .cat-container::-webkit-scrollbar-track {
  /* background: rgba(255, 255, 255, 0.07);  */
  background: white; 
  border-radius: 5px;
}
/* Handle */
.store-category-nav > .cat-container::-webkit-scrollbar-thumb {
  /* background: #888;  */
  background: var(--BTN_COLOR); 
  border-radius: 5px;
  cursor: grab;
}
/* Handle on hover */
.store-category-nav > .cat-container::-webkit-scrollbar-thumb:hover {
  /* background: #555;  */
  background: var(--BTN_COLOR); 
}


.store-category-nav > .cat-container > span{
    white-space: nowrap;
    /* background-color: blue; */
    margin: 0px 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: thin solid transparent;
    cursor: pointer;
    color: grey;
}
.store-category-nav > .cat-container > span.active{
    border-bottom: thin solid var(--MAIN_COLOR);
    color: black;
}

.store-category-nav > .cat-popup{
    width: auto;
    height: auto;
    max-height: 50vh;
    padding: 10px 20px 10px 10px;
    background-color: var(--BG_WHITE_COLOR);
    position: absolute;
    top: 40px;
    z-index: 5;
    overflow: hidden;
    overflow-y: auto;
}
.store-category-nav > .cat-popup > .widget{
    margin-bottom: 5px;
    cursor: pointer;
    color: grey;
    width: calc(100vw - 40px);
    /* max-width: calc(100vw - 50px); */
    max-width: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.store-category-nav > .cat-popup > .widget.active{
    border-bottom: thin solid var(--MAIN_COLOR);
    color: black;
}
.store-category-nav > .cat-popup > .widget > p{
    font-size: 0.9em;
}
.store-category-nav > .cat-popup > .widget > span{
    width: 90%;
    max-width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.store-item-container{
    width: 100%;
    /* background-color: red; */
}
.store-item-container > .title{
    font-weight: bold;
    width: 100%;
    margin-top: 20px;
}

.store-cat-con{
    width: 100%;
}
.store-item-widget{
    height: auto;
    width: calc( (100% / 2) - 25px );
    padding: 10px;
    background-color: var(--BG_WHITE_COLOR);
    margin-top: 10px;
    float: left;
    color: black;
    text-decoration: none;
    position: relative;
}
.store-item-widget:nth-child(odd){
    margin-right: 10px;
}


.store-item-widget > img{
    height: 100px;
    width: 100px;
    padding: 5px;
    background-color: inherit;
    border-radius: 10px;
    object-fit: cover;
}
.store-item-widget > .details{
    width: calc(100% - 120px);
    max-width: calc(100% - 120px);
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
}

.store-item-widget > .details span{
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    float: left;
}
.store-item-widget > .details .name{
    font-weight: bold;
    font-size: 1.1em;

}
.store-item-widget > img.alert{
    height: 15px;
    width: 15px;
    background-color: var(--RED_PALETTE);
    border-radius: 50%;
    margin-left: 10px;
    position: absolute;
    right: 5px;
    top: 5px;
}









.store-item-content{
    width: 100%;
    /* set this if  you want persistent 100% width */
}
.store-item-content > .imgal{
    height: 350px;
    width: calc(100%);
    min-width: calc(100% - 20px);
    overflow: hidden;
    margin-top: 10px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}
.store-item-content > .image-gallery{
    height: 100%;
    /* background-color: black; */
}

.store-item-content .image-gallery-slides{
    max-height: calc(100vw - 20px);
    /* background-color: red; */
}
.store-item-content .image-gallery-slide img{
    max-height: 350px;
    height: 350px;
    max-width: var(--MAX_WIDTH);
    object-fit: cover;
}
.store-item-content > .imgal .image-gallery-slide-wrapper > button.image-gallery-right-nav{
    display: none;
}
.store-item-content > .imgal .image-gallery-slide-wrapper > button.image-gallery-left-nav{
    display: none;
}

.store-item-content > .title{
    font-weight: bold;
    font-size: 1.2em;
    margin: 5px 0px;
    width: 100%;
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
}

.store-item-content > .field{
    margin-bottom: 10px;
    width: calc(100%);
}
.store-item-content > .field > span{
    font-size: 0.9em;
    background-color: var(--BG_WHITE_COLOR);
    padding: 5px 10px;
    border-radius: 5px;
    color: black;
}
.store-item-content > .field > .description{
    font-size: 0.9em;
    background-color: var(--BG_WHITE_COLOR);
    padding: 5px 10px;
    border-radius: 5px;
    color: black;
}
.store-item-content > .field > .alert{
    background-color: var(--RED_PALETTE);
    padding: 3px 10px;
    color: white;
    position: relative;
    padding-right: 20px;
    max-width: calc(100% - 40px);
}
.store-item-content > .field > .alert img{
    height: 20px;
    width: 20px;
    position: absolute;
    top: -10px;
    right: -15px;
    padding: 2px;
    background-color: var(--RED_PALETTE);
    border-radius: 50%;
    border: 3px solid white;
}

.store-item-content > .field > .tags > a{
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 5px;
    padding: 3px 10px;
    margin: 3px;
    font-size: 0.9em;
    text-decoration: none;
    color: black;
}
.store-item-content > .field > .tags > a > b{
    font-weight: normal;
    font-size: 0.8em;
}
.store-item-content > .field > .availability > span{
    font-size: 0.9em;
    padding: 0px 10px;
    border-radius: 5px;
    color: black;
    width: 50%;
    margin-bottom: 5px;
}
.store-item-content > .field > .availability > span > p{
    background-color: var(--BG_WHITE_COLOR);
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 0.9em;
}

.store-item-content > .field > .price{
    background-color: var(--BG_WHITE_COLOR);
    background-color: var(--MAIN_COLOR);
    width: calc(100% - 20px);
    padding: 5px 10px;
    margin-bottom: 5px;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    position: relative;
}
.store-item-content > .field > .price > span{
    font-size: 0.9em;
    padding: 0px;
    border-radius: 5px;
    /* color: black; */
    width: 100%;
    /* width: 100%; */
    font-weight: bold;
}
.store-item-content > .field > .price > span > p{
    font-size: 0.9em;
    font-weight: normal;
}
.store-item-content > .field > .price > .note{
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.store-item-content > .field > .price > .cart{
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: white;
    padding: 0px 5px;
    border-radius: 3px;
}
.store-item-content > .field > .price > .cart p{
    font-size: 0.7em;
    margin-left: 5px;
    color: var(--MAIN_COLOR);
}
.store-item-content > .field > .price > .cart .basket{
    color: var(--MAIN_COLOR);
}

.store-item-content > .field > .blank{
    width: 100%;
    height: 350px;
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 5px;
}





.sticky {
    position: fixed;
    top: 0;
    margin-top: 0px;
    width: 100%;
    max-width: var(--MAX_WIDTH);
    padding-bottom: 10px;
    z-index: 5;
}

.sticky + .store-item-container {
    padding-top: 50px;
}

.subtitle{
    font-size: 0.8em;
}
.error{
    color: var(--RED_PALETTE);
}






.store-orders-container{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6px);

}
.store-basket{
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
}
.store-basket > svg{
    padding: 10px;
    height: 30px;
    width: 30px;
    background-color: var(--MAIN_COLOR);
    color: white;
    border-radius: 50%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}
.store-orders-container .close{
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    width: 20px;
    padding: 10px;
}
.store-orders-container .store-orders{
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
    height: auto;
    max-height: 90vh;
    /* min-height: 350px; */
    padding: 10px;
    position: relative;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    overflow: hidden;
    overflow-y: auto;
}
.store-orders-container .store-orders > .empty{
    width: 100%;
    padding: 20px 0px;
    /* background-color: rgb(200, 200, 200); */
    color: grey;
    border-radius: 10px;
    border: 2px dashed grey;
}
.store-orders-container .store-orders > .title{
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
}

.store-orders-container .store-orders > .widget{
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}
.store-orders-container .store-orders > .widget > .header{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    /* border-radius: 10px; */
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 5px;
}
.store-orders-container .store-orders > .widget > .option{
    width: calc(100% - 20px);
    padding: 5px 10px;
    background-color: var(--BG_WHITE_COLOR);
    /* border-radius: 10px; */
    margin-bottom: 5px;
    color: grey;
    position: relative;
    overflow: hidden;
}
.store-orders-container .store-orders > .widget > .option:last-child{
    border-radius: 0px 0px 10px 10px;
}
.store-orders-container .store-orders > .widget > .option > .opwid{
    width: 100%;
    padding: 2.5px 0px;
}
.store-orders-container .store-orders > .widget > .option > .opwid.subtotal{
    /* font-size: 0.8em; */
    border-top: thin solid rgba(130, 130, 130, 0.2);
    color: black;
}
.store-orders-container .store-orders > .widget > .option > .opwid.subtotal > p{
    margin-right: 17px;
}
.store-orders-container .store-orders > .widget > .option > .opwid > .action > svg{
    height: 12px;
    width: 12px;
    margin-left: 5px;
}
.store-orders-container .store-orders > .widget > .option > .opwid > span{
    width: calc(100% - 60px);
    max-width: calc(100% - 60px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
/* .store-orders-container .store-orders > .widget > .option > .delete{
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 15px;
    width: 15px;
    padding: 5px;
} */









@media (max-width: 767px) { /* mobile */
    .store-header > .logo-banner{
        position: relative;
        height: 30vh;
        width: 100%;
    }
    .store-header > .logo-banner > .banner{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .store-header > .logo-banner > .logo{
        width: 70px;
        height: 70px;
        position: absolute;
        bottom: 5px;
        left: 5px;
        background-color: var(--BG_WHITE_COLOR);
        padding: 5px;
        border-radius: 10px;
    }
    .store-header > .logo-banner > .dineSafePass{
        position: absolute;
        bottom: 10px;
        right: 10px;
        max-height: 70px;
    }
    .store-category-nav > .cat-popup > .widget{
        max-width: calc(100vw - 50px);
    }
    .sticky {
        max-width: calc(100vw - 20px);
    }

    .store-item-widget{
        width: calc(100% - 20px);
    }
    .store-item-widget:nth-child(odd){
        margin-right: 0px;
    }

    /* .store-item-content .image-gallery-slides img{
        max-height: calc(100vw - 20px);
        object-fit: cover;
    } */
    .store-item-content .image-gallery-slide img{
        /* max-height: calc(var(--MAX_WIDTH) - 20px); */
        max-height: 30vh;
        height: 30vh;
        object-fit: cover;
        /* background-color: red; */
    }
    .store-item-content > .imgal{
        height: auto;
    }
    .store-item-content > .field > .availability > span{
        font-size: 0.9em;
        padding: 0px;
        border-radius: 5px;
        color: black;
        width: 100%;
        margin-bottom: 5px;
    }

}