/* .business{
    width: 100vw;
} */
.business-container{
  width: calc(100vw);
  /* background-color: blue; */
}
.business-widget-container{
  width: calc(100% - 20px);
  max-width: var(--MAX_WIDTH);
  position: relative;
  border-bottom: thin solid rgba(128, 128, 128, 0.2);
}
.business-widget,
.business-widget-add{
  width: calc(100% - 20px);
  position: relative;
  padding-bottom: 10px;
  /* margin-bottom: 10px; */
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  /* background-color: red; */
}
.business-widget *{
  cursor: pointer;
}
/* .business-widget span,
.business-widget img{
  cursor: pointer;
} */
.business-widget-container:hover{
  background-color: var(--BG_WHITE_COLOR);
}
.business-widget > img{
  height: 100px;
  width: 100px;
  object-fit: cover;
}
.business-widget-container > img.delete{
  position: absolute;
  top: 0px;
  right: 0px;
  height: 20px;
  width: 20px;
  padding: 10px;
  background-color: white;
}
.business-widget-container:hover > img.delete{
  background-color: var(--BG_WHITE_COLOR);
}
.business-widget-container > .subtitle{
  font-size: 0.7em;
  color: grey;
}

.business-widget > i{
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 1.2em;
  padding: 10px;
  /* background-color: red; */
}


.business-widget > .details{
  margin-left: 10px;
  /* cursor: pointer; */
  /* background-color: red; */
  width: calc(100% - 100px);
  max-width: calc(100% - 100px);
}
.business-widget > .details > .store-name{
    max-width: calc(100% - 15px);
    font-size: 1.2em;
    font-weight: bold;
    letter-spacing: 1.2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    float: left;

}
.business-widget > .details > .text{
  /* margin-bottom: 5px; */
  /* font-size: 0.9em; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    float: left;
}
.business-widget > .details > .text > i{
    font-size: 0.8em;
    margin-left: 3px;
    color: var(--BTN_COLOR);
}

.business-widget-add >.add-new-business{
  width: calc(100% - 40px);
  max-width: var(--MAX_WIDTH);
}

.business-widget-add >.add-new-business > a{
  padding: 3px 20px;
  background-color: var(--MAIN_COLOR);
  display: flex;
  align-items: center;
  justify-content: center;
}
.business-widget-add >.add-new-business > a > img{
  height: 40px;
}
.business-widget-add >.add-new-business > input{
  height: 100%;
  width: 100%;
  padding: 10px 0px 10px 10px;
  border: none;
  font-size: 1.4em;
  background-color: var(--BG_WHITE_COLOR);
}
.business-widget-add > .subtitle{
  font-size: 0.7em;
  text-align: center;
  margin-top: 5px;
}
.business-widget-add > .title > .subtitle{
  font-size: 0.7em;
  text-align: center;
}
.business-widget-popup{
  width: 100%;
  max-width: var(--MAX_WIDTH);
  margin-bottom: 10px;
  /* background-color: red; */
}
.business-widget-popup > .delete{
  background-color: var(--RED_PALETTE);
  background-color: var(--MAIN_COLOR);
  color: white;
  padding: 5px 5px 5px 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
}
.business-widget-popup > .delete > img{
  height: 20px;
  width: 20px;
  padding: 3px;
  background-color: whitesmoke;
  margin-left: 5px;
  border-radius: 5px;
}
.business-widget-popup > .change{
  background-color: var(--BLUE_PALETTE);
  background-color: var(--MAIN_COLOR);
  color: white;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.5);
  max-width: 65%;
}
.business-widget-popup > .change > img{
  height: 20px;
  width: 20px;
  padding: 3px;
  background-color: whitesmoke;
  /* background-color: var(--GREEN_PALETTE); */
  /* margin-left: 5px; */
  border-radius: 0px 5px 5px 0px;
  border-radius: 5px;
}
.business-widget-popup > .change > img.red{
  background-color: var(--RED_PALETTE);
}
.business-widget-popup > .change > img.green{
  background-color: var(--GREEN_PALETTE);
}
.business-widget-popup > .change > img.yellow{
  background-color: var(--YELLOW_PALETTE);
}
.business-widget-popup > .change > input{
  border: none;
  background-color: var(--BG_WHITE_COLOR);
  background-color: var(--BLUE_PALETTE);
  background-color: var(--MAIN_COLOR);
  color: white;
  border-radius: 5px 0px 0px 5px;
  padding: 5.5px 0px;
  padding-left: 10px;
}













.create-business-protocol{
  width: 100%;
  /* background-color: red; */
}
.create-business-protocol .container{
  width: calc(100% - 20px);
  max-width: var(--MAX_WIDTH);
}
.create-business-protocol .container > h1{
  align-self: center;
  text-align: center;
}
.create-business-protocol .container > .subtitle{
  font-size: 0.8em;
}
.create-business-protocol .container > .title{
  font-size: 1.2em;
  font-weight: bold;
}

.create-business-protocol .container > label{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 5px;
}
.create-business-protocol .container > label > input{
  position: relative;
  bottom: -3px;
  min-width: 20px
}
.create-business-protocol .container > label > img{
  margin: 0px 10px;
}
.create-business-protocol .container > button{
  align-self: center;
  font-weight: bold;
  font-size: 1.2em;
}