.nearby-container{
    width: 100vw;
    height: calc(100vh - 78px);
    position: relative;
}

.nearby-container > .iframe{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
}
.nearby-container > .iframe > img{
    display: none;
}
.nearby-container > .search{
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
    padding: 5px 10px;
    position: absolute;
    top: 20px;
    z-index: 5;
    
}

.nearby-container > .search > div[data-reach-combobox]{
    /* background-color: red; */
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 5px 0px black;
    background-color: var(--BG_WHITE_COLOR);
    border-radius: 5px;
    /* overflow: hidden; */
}
.nearby-container > .search input{
    width: calc(100% - 100px);
    margin-left: 10px;
    border: none;
    font-size: 1.4em;
    background-color: var(--BG_WHITE_COLOR);
    /* background-color: red; */
}
.nearby-container > .search img{
    height: 30px;
    width: 30px;
    object-fit: cover;
    padding: 5px;

}
.nearby-container > .search ul{
    width: calc(100% - 20px);
    position: absolute;
    top: 50px;
    left: 0px;
    background-color: var(--BG_WHITE_COLOR);
    background-color: rgb(245, 245, 245);
    padding: 10px;
}
.nearby-container > .search ul li{
    border-bottom: thin solid rgba(128, 128, 128, 0.5);
    margin-bottom: 10px;
    color: #d5c499;
}

.nearby-container > button{
    position: absolute;
    top: 100px;
    z-index: 5;
}



.nearby-mapcard{
    height: auto;
    width: calc(100% - 40px);
    max-width: var(--MAX_WIDTH);
    padding: 10px;
    /* background-color: var(--MAIN_COLOR); */
    background-color: #d5c499;
    /* background-color: var(--BG_WHITE_COLOR); */
    /* background-color: white; */
    z-index: 5;
    position: absolute;
    bottom: 10px;
    border-radius: 15px;
}
.nearby-mapcard .close{
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    width: 20px;
    padding: 5px;
    background-color: inherit;
    border-radius: 0px 0px 0px 10px;
    z-index: 5;
}
.nearby-mapcard > .store{
    height: 150px;
    width: 100%;
    margin-bottom: 10px;
    position: relative;
}
.nearby-mapcard > .store .banner{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.nearby-mapcard > .store .logo{
    position: absolute;
    bottom: 5px;
    left: 5px;
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px;
}
.nearby-mapcard .featured{
    width: 100%;
}
.nearby-mapcard .featured > a {
    width: calc( (100% / 5) - 10px );
}
.nearby-mapcard .featured > a > img{
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}
.nearby-mapcard .about{
    width: 100%;
    height: auto;
    /* background-color: red; */
    font-size: 0.8em;
    color: white;
}
.nearby-mapcard .about > .storename{
    font-size: 1.4em;
    /* font-weight: bold; */
}
.nearby-mapcard .about > .info{
    /* margin-left: 10px; */
    color: white;
}
.nearby-mapcard .about img{
    height: 20px;
    width: 20px;
    margin-right: 5px;
    margin-left: 15px;
}
.nearby-mapcard .about > .info > p{
    margin: 0px 10px;
    font-size: 2em;
}




@media (max-width: 767px) { /* mobile */
    .nearby-mapcard > .store{
        height: 100px;
        width: 100%;
    }
    .nearby-mapcard > .store > .banner{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        margin-bottom: 10px;
    }
    .nearby-mapcard > .store .logo{
        position: absolute;
        bottom: 5px;
        left: 5px;
        height: 70px;
        width: 70px;
        object-fit: cover;
        border-radius: 10px;
    }
    .nearby-mapcard .featured > a {
        width: calc( (100% / 3) - 5px );
    }
    .nearby-mapcard .featured > a > img{
        width: 100%;
        height: 70px;
        object-fit: cover;
        border-radius: 10px;
    }
    .nearby-mapcard .about{
        width: 100%;
        height: auto;
        font-size: 0.8em;
    }
    .nearby-mapcard .about img{
        height: 20px;
        width: 20px;
        margin-right: 5px;
        margin-left: 10px;
    }
    .nearby-mapcard .about > .info > p{
        margin: 0px 10px;
        font-size: 2em;
    }
    .nearby-mapcard .about > .info span{
        margin-bottom: 10px;
    }
    

}